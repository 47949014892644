import Services from '../../../services'

class LaporanPurchaseRequestApi {
    page(params) {
        return Services.get('/laporan_purchase_request/page', { params })
    }

    single(params) {
        return Services.get('/laporan_purchase_request/single', { params })
    }
}

export default new LaporanPurchaseRequestApi()