import { DropdownButton, Spinner } from "react-bootstrap"

const ExportButton = ({ loading, children, className, text }) => (
    <DropdownButton 
        className={className}
        title={loading ? <><Spinner animation="border" variant="white" size="sm" /><span className="visually-hidden ml-2">Memuat...</span></> : text ? text : "Export Data"}
        variant="warning text-white"
        disabled={loading}
    >
        {children}
    </DropdownButton>
)

export default ExportButton