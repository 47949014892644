import React from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  InfoItemHorizontal
} from 'components'
import {
  DateConvert
} from 'utilities'

export const SectionDetail = ({data}) => {
  return (
    <Row>
      <Col md>
        <InfoItemHorizontal 
          label="Tgl. Job Order"
          text={data?.tgl_job_order ? DateConvert(new Date(data.tgl_job_order)).detail : '-'}
        />
        <InfoItemHorizontal 
          label="No. Job Order"
          text={data?.no_job_order ?? '-'}
        />
        <InfoItemHorizontal 
          label="Tgl. Permintaan Produksi"
          text={data?.tgl_permintaan_produksi ? DateConvert(new Date(data.tgl_permintaan_produksi)).detail : '-'}
        />
        <InfoItemHorizontal 
          label="No. Permintaan Produksi"
          text={data?.no_permintaan_produksi ?? '-'}
        />
      </Col>
      <Col md>
        <InfoItemHorizontal 
          label="Customer"
          text={data?.nama_customer ?? '-'}
        />
        <InfoItemHorizontal 
          label="Kode Barang"
          text={data?.kode_item ?? '-'}
        />
        <InfoItemHorizontal 
          label="Item Barang"
          text={data?.nama_item ?? '-'}
        />
        <InfoItemHorizontal 
          label="Qty. Job Order"
          text={`${data?.qty_produksi ? parseFloat(data.qty_produksi) : '-'} ${data?.satuan_pakai ?? ''}`}
        />
      </Col>
    </Row>
  )
}