// React
import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";

// API
import Axios from "axios";
import { DeliveryOrderApi } from "../../../../api";

// Form
import * as Yup from "yup";
import { Formik } from "formik";

// Component
import { Alert, BackButton, DataStatus } from "../../../../components";
import { Card } from "react-bootstrap";

// Page Component
import { FormSection, InfoSection } from "./Section";

const TambahDeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  // data
  const [data, setData] = useState([]);
  // menampung data yang akan dihapus

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  });

  const { id_sales_order, id_gudang } = useParams();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([DeliveryOrderApi.getFormData({ id_sales_order, id_gudang })])
      .then(
        Axios.spread((res) => {
          setData({
            ...res.data.data,
            nama_gudang: location?.state?.data?.nama_gudang ?? null,
            alamat_pengiriman: location?.state?.data?.alamat_pengiriman ?? null
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Delivery Order");

    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNavbarTitle]);

  const TambahData = () => {
    const formInitialValues = {
      id_sales_order: id_sales_order,
      tgl_delivery_order: "",
      no_delivery_order: "",
      catatan_delivery_order: "",
    };

    const formValidationSchema = Yup.object().shape({
      tgl_delivery_order: Yup.string().required("Masukan tanggal Delivery Order "),
      no_delivery_order: Yup.string().required("Masukan no Delivery Order "),
      // catatan_delivery_order: Yup.string().required("Masukan catatan Delivery Order"),
    });

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      const detail = data.detail?.map((val) => {
        return {
          id_sales_order: val.id_sales_order,
          id_sales_order_detail: val.id_sales_order_detail,
          qty: parseInt(val.qty_item),
        };
      });
      const finalValues = {
        ...values,
        detail: detail,
        id_gudang,
      };
      DeliveryOrderApi.create(finalValues)
        .then(() => {
          history.push("/inventory/transaksi/delivery-order", {
            variant: "primary",
            text: "Data berhasil di tambah",
            status: true,
          });
        })
        .catch((err) => {
          history.push("/inventory/transaksi/delivery-order", {
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <>
        <InfoSection data={data} />

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <FormSection
                data={data}
                isLocation={true}
                location={location}
                values={values}
                errors={errors}
                touched={touched}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                type="create"
              />
            </form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <b className="text-capitalize">Tambah Delivery Order</b>

        <BackButton
          onClick={() => {
            history.push("/inventory/transaksi/delivery-order/list-so", { ...location?.state });
          }}
        />
      </Card.Header>
      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data.length !== 0 ? (
          <TambahData />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahDeliveryOrder;
