// Config
import Logo from "config"

const KopSuratSection = () => (
	<div className="d-flex mx-2 mb-4">
		<img src={Logo.LOGO} alt="" width={110} />
		<div style={{ marginLeft: 30 }}>
			<div className="font-weight-bold">PT. PUSPA BERAS BALI</div>
			<div>Pengeragoan Dauh Tukad, Pengeragoan, Pekutatan, Jembrana, Bali.</div>
			<div>Telp. : +62 819-0416-8666</div>
			<div>Email : puspaberasbali@gmail.com</div>
		</div>
	</div>
)

export default KopSuratSection