import React, { Component } from "react";
import { 
    THead, TBody, Tr, TdFixed, Th, Table
} from "../../../../components";

export class ShowData extends Component {
  render() {
    return (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          <span style={{ textTransform: "capitalize" }}>{this.props.title}</span>
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {this.props.text ? this.props.text : "-"}
        </div>
      </div>
    );
  }
}

export class Cetak extends Component {
  render() {
    let data = this.props.printData;

    return (
      <div style={{ marginTop: 100 }} className="px-4 mx-2">
        <div className="row">
          <div className="col-6">
            <ShowData title="Tgl. Sales Order" text={data.tgl_sales_order} />
          </div>
          <div className="col-6">
            <ShowData title="No. Sales Order" text={data.no_sales_order} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ShowData title="Tgl. Delivery Order" text={data.tgl_delivery_order} />
          </div>
          <div className="col-6">
            <ShowData title="No. Delivery Order" text={data.no_delivery_order} />
          </div>
        </div>
        <div className="mt-3">
            <div className="row">
                <div className="col-6">
                    <ShowData title="Customer" text={data.nama_customer} />
                </div>
                <div className="col-6">
                    <ShowData title="Sales" text={data.nama_sales} />
                </div>
            </div>
        </div>
        <div className="mt-3">
            <ShowData title="Catatan Delivery Order" text={data.catatan_delivery_order} />
        </div>
        <div className="mt-4">List Barang</div>
        <Table>
          <THead>
            <Th>No.</Th>
            <Th>Kode Barang</Th>
            <Th>Nama Barang</Th>
            <Th>Gudang</Th>
            <Th>Qty SO</Th>
            <Th>Satuan</Th>
          </THead>
          <TBody>
            {data?.detail?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_barang}</TdFixed>
                <TdFixed>{val.nama_barang}</TdFixed>
                <TdFixed>{val.nama_gudang}</TdFixed>
                <TdFixed>{val.qty}</TdFixed>
                <TdFixed>{val.nama_satuan}</TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </div>
    );
  }
}

export default Cetak
