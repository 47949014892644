import Axios from "axios";

const { REACT_APP_FILE_BASE_URL } = process.env;

const UploadServices = Axios.create({
  baseURL: REACT_APP_FILE_BASE_URL,
});

UploadServices.interceptors.request.use((config) => {
  const token = "Bearer " + sessionStorage.getItem("token");

  config.headers = {
    Authorization: token,
    "Api-Key": "TUdQQmFuZ3NhdFBhbnRla0FzdQ==",
    "Content-Type": "application/json",
  };

  return config;
});

export default UploadServices;
