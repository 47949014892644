import React, { useState, useEffect } from 'react'
import { Card } from "react-bootstrap"
import { useHistory, useParams, useLocation } from "react-router-dom"
import { RupiahConvert, DateConvert, DecimalConvert } from "utilities"
import {
    CRUDLayout,
    TdFixed,
    DataStatus,
    Tr,
    Alert,
    Th,
    Td,
    THead,
    TBody,
    ThFixed,
    BackButton,
    InfoItemHorizontal,
    Table
} from "components"
import { PenerimaanBerasApi } from "api"

const PenerimaanBerasDetail = ({ setNavbarTitle }) => {
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()

    const [readData, setReadData] = useState([])
    const [loading, setLoading] = useState(false)

    const [alertConfig, setAlertConfig] = useState({
        show: false,
        variant: "primary",
        text: "",
    })

    const getInitialData = () => {
        setLoading(true);

        PenerimaanBerasApi.getSingle({ id_penerimaan_beras: id })
        .then(data => setReadData(data?.data?.data ?? []))
        .catch(() => {
            setAlertConfig({
                show: true,
                variant: "danger",
                text: "Data gagal dimuat!",
            })
        })
        .finally(() => setLoading(false))
    }
    useEffect(() => {
        setNavbarTitle("Penerimaan Barang")
        getInitialData()
    }, [])

    const grandTotal = () => {
        const totalKeseluruhanHarga = readData?.detail?.reduce((prevValue, currentValue) => {
          const checkTotal = currentValue.total_harga ? parseFloat(currentValue.total_harga) : 0
          return prevValue + checkTotal
        }, 0)
  
        return {
          prices: totalKeseluruhanHarga,
        }
    }

    const InfoSection = () => (
        <div className="p-3">
            <InfoItemHorizontal label="Tgl. Penerimaan Beras" text={readData?.tgl_penerimaan_beras ? DateConvert(new Date(readData?.tgl_penerimaan_beras)).detail : "-"} />
            <InfoItemHorizontal label="No. Penerimaan Beras" text={readData?.no_penerimaan_beras ?? "-"} />
            <InfoItemHorizontal label="Supir" text={readData?.supir ?? "-"} />
            <InfoItemHorizontal label="Plat Kendaraan" text={readData?.plat_kendaraan ?? "-"} />
            <InfoItemHorizontal label="Berat Truk Masuk" text={readData?.berat_truk_masuk ? `${DecimalConvert(readData?.berat_truk_masuk ?? 0).getWithComa} Kilogram` : "-"} />
            <InfoItemHorizontal label="Berat Truk Keluar" text={readData?.berat_truk_keluar ? `${DecimalConvert(readData?.berat_truk_keluar ?? 0).getWithComa} Kilogram` : "-"} />
            <InfoItemHorizontal label="Total Muatan Truk" text={readData?.total_muatan_truk ? `${DecimalConvert(readData?.total_muatan_truk ?? 0).getWithComa} Kilogram` : "-"} />
            <InfoItemHorizontal label="Keterangan" text={readData?.keterangan ?? "-"} />
        </div>
    )
    const TableSection = () => (
        <div className="p-3">
        <div className="font-weight-bold mb-2">List Data Penerimaan Beras</div>
            <Table>
                <THead>
                    <Tr>
                        <ThFixed>No</ThFixed>
                        <Th>Kode Item</Th>
                        <Th>Nama Beras</Th>
                        <Th>Qty Penerimaan</Th>
                        <Th>Satuan</Th>
                        <Th>Gudang Penerimaan</Th>
                        <Th>Harga Satuan</Th>
                        <Th>Total harga</Th>
                    </Tr>
                </THead>
                <TBody>
                    {readData?.detail?.length > 0 
                        ? readData?.detail.map((val, index) => {
                            return(
                                <Tr>
                                    <TdFixed>{index + 1}</TdFixed>
                                    <Td>{val.kode_item ?? "-"}</Td>
                                    <Td>{val.nama_item ?? "-"}</Td>
                                    <Td className="text-right">{val.qty_penerimaan ? DecimalConvert(val.qty_penerimaan).getWithComa : 0}</Td>
                                    <Td>{val.nama_satuan ?? "-"}</Td>
                                    <Td>{val.nama_gudang ?? "-"}</Td>
                                    <Td className="text-right">{val.harga_satuan ? RupiahConvert(String(parseFloat(val.harga_satuan))).getWithComa : "Rp.0"}</Td>
                                    <Td className="text-right">{val.total_harga ? RupiahConvert(String(parseFloat(val.total_harga))).getWithComa : "Rp.0"}</Td>
                                </Tr>
                            )})
                        : <Tr><Td colSpan="8"><div className="text-center">Tidak ada data</div></Td></Tr>
                    }
                    {readData?.detail?.length > 0 &&
                        <Tr>
                            <Td colSpan={7} className="text-right"><b>Total Keseluruhan Harga</b></Td>
                            <Td className="text-right">{RupiahConvert(String(parseFloat(grandTotal().prices))).getWithComa}</Td>
                        </Tr>
                    }
                </TBody>
            </Table>
        </div>
    )

    
    return (
        <CRUDLayout>
            <CRUDLayout.Head>
                <CRUDLayout.HeadButtonSection>
                <BackButton onClick={() => history.push("/inventory/transaksi/penerimaan-beras", { ...location?.state })}/>
                </CRUDLayout.HeadButtonSection>
            </CRUDLayout.Head>
            <div className="font-weight-bold mb-2">Detail Data Penerimaan Beras</div>
            <Card>
                <Alert
                    show={alertConfig?.show}
                    showCloseButton={true}
                    variant={alertConfig.variant}
                    text={alertConfig.text}
                    onClose={() => setAlertConfig({ show: false })}
                />
                {loading === true 
                    ? <DataStatus loading={true} text="Memuat data..." /> 
                    : <>
                        <InfoSection />
                        <TableSection />
                      </>
                }
            </Card>
        </CRUDLayout>
    )
}

export default PenerimaanBerasDetail