import { useState, useEffect } from 'react'
import { Modal, Row, Col, Card } from "react-bootstrap"
import { PenerimaanBarangApi } from 'api'
import { ReadModal, DataStatus } from "components"
import { DateConvert } from 'utilities'

const ModalDetailPenerimaanBarang = ({ modalConfig, setModalConfig }) => {
  const { id_purchase, id_penerimaan } = modalConfig?.data ?? {}
  const [readData, setReadData] = useState({
    qty_beli: "0.00",
    qty_pakai: "0.00",
    tgl_purchase_order: "",
    tgl_penerimaan_barang: "",
    qty_order: "0.00",
  })
  const [loadingModal, setLoadingModal] = useState(false)

  const getDetail = () => {
    setLoadingModal(true)

    if (id_purchase) {
      PenerimaanBarangApi.getSinglePenerimaan("id_penerimaan_barang", id_penerimaan)
        .then((res) => setReadData(res.data.data))
        .catch((err) => alert(err.response.data.message))
        .finally(() => setLoadingModal(false))
    }
  }

  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    )

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Penerimaan Barang</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {readData.stakeholder === undefined
              ? "Memuat Data"
              : readData.stakeholder.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== "PEN" && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" || val.status_approval === "REV"
                            ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""
                            }`
                            : val.status_approval === "APP"
                              ? "Pengesah"
                              : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                    </Col>
                  )
              )}
          </Row>
        </Card.Body>
      </Card>
    )
  }

  const ShowData = ({ title, text, line }) => (
    <div>
      <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
        {title}
      </small>
      <div className="text-dark">
        <small>{text ? text : "-"}</small>
      </div>
      {line && <hr className="my-2" />}
    </div>
  )

  useEffect(() => {
    getDetail()
  }, [])

  return (
    <ReadModal
      size="lg"
      show={Boolean(modalConfig.show && modalConfig.type === 'detail')}
      onHide={() => setModalConfig({ show: false, type: '', data: {} })}
      title="Penerimaan Barang">
      <Modal.Body>
        {loadingModal === true
          ? (<DataStatus loading={loadingModal} text="Memuat data..." />)
          : (
            <>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Purchase Order"
                    line={true}
                    text={
                      readData.tgl_purchase_order
                        ? DateConvert(new Date(readData.tgl_purchase_order)).detail
                        : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Purchase Order"
                    line={true}
                    text={readData.no_purchase_order ? readData.no_purchase_order : "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Tgl. Penerimaan Barang"
                    line={true}
                    text={
                      readData.tgl_penerimaan_barang
                        ? DateConvert(new Date(readData.tgl_penerimaan_barang)).detail
                        : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="No. Penerimaan Barang"
                    line={true}
                    text={readData.no_penerimaan_barang ? readData.no_penerimaan_barang : "-"}
                  />
                </Col>
              </Row>
              <Row>
                {" "}
                <Col lg="12">
                  <ShowData title="Item" line={true} text={readData.nama_item} />
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <ShowData
                    title="Vendor"
                    line={true}
                    text={readData.nama_vendor ? readData.nama_vendor : "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="No. Surat Jalan"
                    line={true}
                    text={readData.no_surat_jalan ? readData.no_surat_jalan : "-"}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Petugas Penerima"
                    line={true}
                    text={readData.nama_karyawan ? readData.nama_karyawan : "-"}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Gudang Penerima"
                    line={true}
                    text={readData.nama_gudang ? readData.nama_gudang : "-"}
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Qty Purchase Order"
                    line={true}
                    text={
                      readData.qty_order
                        ? readData.qty_order.replace(".", ",") + " " + readData.nama_satuan_order
                        : "-"
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <ShowData
                    title="Dalam Satuan Beli"
                    line={true}
                    text={
                      readData.qty_beli ? `${readData.qty_beli} ${readData.nama_satuan_beli}` : "-"
                    }
                  />
                </Col>
                <Col lg="6">
                  <ShowData
                    title="Dalam Satuan Pakai"
                    line={true}
                    text={
                      readData.qty_pakai
                        ? `${readData.qty_pakai} ${readData.nama_satuan_pakai}`
                        : "-"
                    }
                  />
                </Col>
              </Row>
              <div className="row justify-content-center mt-2">
                <Col lg="10">
                  <div className="text-center">
                    <img src={readData.foto_surat_jalan} className="img-fluid" width="400" />
                  </div>
                </Col>
              </div>
              <FormCard />
            </>
          )}
      </Modal.Body>
    </ReadModal>
  )// 
}

export default ModalDetailPenerimaanBarang