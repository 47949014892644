// React
import React, { useState, useEffect } from "react";

// Components
import {
  Tr,
  Th,
  Td,
  TBody,
  THead,
  TdFixed,
  ThFixed,
  CRUDLayout,
  Input,
  InputSearch,
  SelectSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Select,
  Alert,
  Switch,
  Pagination,
  ListData,
  ReadButton,
  ReadModal,
  TextArea,
  Checkbox,
} from "components";
import { Modal } from "react-bootstrap";
import { PageNumber, TableNumber, HeadOfficeStatus } from "utilities";

// Form
import { Formik } from "formik";
import * as Yup from "yup";

// API
import Axios from "axios";
import { RegItemBahanApi } from "api";

// View Components
import { Checkbox as ViewCheckbox } from "./components";

const RegistrasiItemBahan = ({ setNavbarTitle }) => {
  // Title
  const title = "Item Barang";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isReadForm, setIsReadForm] = useState(false); // MODAL DETAIL STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA PABRIKAN
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);

  // STATE MENAMPUNG GENERATE KODE ITEM
  const [kodeItem, setKodeItem] = useState([]);
  // STATE MENAMPUNG DATA BUASO SELECT
  const [buasoData, setBuasoData] = useState([]);
  // STATE MENAMPUNG DATA SATUAN SELECT SEARCH
  const [satuanData, setSatuanData] = useState([]);
  // STATE MENAMPUNG DATA KELOMPOK SELECT SEARCH
  const [kelompokData, setKelompokData] = useState([]);
  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    Axios.all([
      RegItemBahanApi.getPage(page, dataLength, searchKey),
      RegItemBahanApi.getBuaso(),
      RegItemBahanApi.getSatuan(),
      RegItemBahanApi.getKelompok(),
      RegItemBahanApi.generateKodeItem(),
    ])
      .then(
        Axios.spread((res, buaso, satuan, kelompok, kodeItem) => {
          setData(res.data.data);
          setBuasoData(buaso.data.data);
          setSatuanData(satuan.data.data);
          setKelompokData(kelompok.data.data);
          setKodeItem(kodeItem.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({ variant: "primary", text: `Hasil Pencarian : ${searchKey}` });
        }
        setIsLoading(false);
      });
  };

  // FUNCTION UBAH SWITCH SHOW/HIDE STATUS
  const changeDataStatus = (status, id) => {
    setShowAlert(false);

    const value = { id_item_buaso: id };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? RegItemBahanApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : RegItemBahanApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title);
    getData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };

    // eslint-disable-next-line
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // Handle check duplicate Nama Item Bahan
  const checkDuplicate = (value) => {
    // cek duplicate ketika update data
    if (isUpdateForm) {
      if (updateData.nama_item !== value) {
        return RegItemBahanApi.checkDuplicateNama({ nama_item: value })
          .then(() => true)
          .catch(() => false);
      }
      return true;
    } else {
      // cek duplicate ketika tambah data
      return RegItemBahanApi.checkDuplicateNama({ nama_item: value })
        .then(() => true)
        .catch(() => false);
    }
  };

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_kelompok: Yup.string().required("Pilih Kelompok"),
    bisa_diproduksi: Yup.string().required("Pilih Kelompok"),
    id_jenis: Yup.string().required("Pilih Jenis"),
    id_satuan_pakai: Yup.string().required("Pilih Satuan Pakai"),
    id_satuan_beli: Yup.string().required("Pilih Satuan Beli"),
    id_satuan_jual: Yup.string().required("Pilih Satuan Jual"),
    nama_item: Yup.string()
      .required("Masukkan Nama Item")
      .test("checkDuplicate", "Nama sudah digunakan", (value) =>
        updateData.nama_item !== value || isCreateForm === true
          ? RegItemBahanApi.checkDuplicateNama({ nama_item: value })
              .then(() => true)
              .catch(() => false)
          : true
      ),
  });

  const CheckboxData = () => (
    <>
      <div>
        <ViewCheckbox label="Barang Bisa Diproduksi" name="bisa_diproduksi" />
      </div>

      <div>
        <ViewCheckbox label="Barang Bisa Dijual" name="bisa_dijual" />
      </div>
    </>
  );

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    const [jenisData, setJenisData] = useState([]);
    const [isFetchingJenis, setIsFetchingJenis] = useState(false);

    const getDataJenis = (id) => {
      if (id) {
        setIsFetchingJenis(true);

        RegItemBahanApi.getJenis(id)
          .then((res) =>
            setJenisData(
              res?.data?.data?.map((val) => Object({ value: val.id_jenis, label: val.nama_jenis }))
            )
          )
          .finally(() => setIsFetchingJenis(false));
      }
    };

    const formInitialValues = {
      id_buaso: "1", //ID BUASO BAHAN
      kode_item: kodeItem,
      nama_item: "",
      id_kelompok: "",
      id_jenis: "",
      id_satuan_pakai: "",
      id_satuan_beli: "",
      id_satuan_jual: "",
      keterangan: "",
      tipe: "",
      id_pabrikan: null,
      dimensi: "",
      pj: 0,
      lb: 0,
      tb: 0,
      bisa_diproduksi: false,
      bisa_dijual: false,
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = { ...values, id_satuan: values.id_satuan_pakai };

      RegItemBahanApi.create(finalValues)
        .then(() =>
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          })
        )
        .catch((err) =>
          setAlertConfig({
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          })
        )
        .finally(() => {
          setIsCreateForm(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <CreateModal
        size="lg"
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="container">
                <Modal.Body>
                  <div className="row ">
                    <div className="col-md-6 col-sm-12">
                      <Select
                        label="BUASO"
                        name="id_buaso"
                        onChange={handleChange}
                        errorText={errors.id_buaso}
                        defaultValue={values.id_buaso}
                        disabled={true}
                      >
                        {/* OPTIONS */}
                        {buasoData.map((e) => (
                          <option key={e.id_buaso} value={e.id_buaso}>
                            {e.kode_buaso} - {e.nama_buaso}
                          </option>
                        ))}
                      </Select>

                      <Input
                        label="Kode Item Barang"
                        type="text"
                        name="kode_item"
                        placeholder="Kode item barang"
                        value={values.kode_item}
                        // readOnly={true}
                        onChange={handleChange}
                      />

                      <Input
                        label="Nama Item Barang"
                        type="text"
                        name="nama_item"
                        placeholder="Nama item barang"
                        value={values.nama_item}
                        onChange={handleChange}
                        error={Boolean(errors.nama_item)}
                        errorText={errors.nama_item}
                      />

                      <SelectSearch
                        label="Kelompok Barang"
                        name="id_kelompok"
                        placeholder="Pilih Kelompok"
                        onChange={(val) => {
                          setFieldValue("id_kelompok", val.value);
                          getDataJenis(val.value);
                        }}
                        option={kelompokData.map((val) =>
                          Object({ value: val.id_kelompok, label: val.nama_kelompok })
                        )}
                        defaultValue=""
                        error={errors.id_kelompok && touched.id_kelompok && true}
                        errorText={errors.id_kelompok && touched.id_kelompok && errors.id_kelompok}
                      />

                      <SelectSearch
                        label="Jenis Barang"
                        name="id_jenis"
                        placeholder={
                          values.id_kelompok ? "Pilih Jenis" : "Pilih Kelompok Terlebih Dahulu"
                        }
                        isDisabled={!values.id_kelompok}
                        loading={isFetchingJenis}
                        onChange={(val) => setFieldValue("id_jenis", val.value)}
                        option={jenisData ?? []}
                        defaultValue=""
                        error={errors.id_jenis && touched.id_jenis && true}
                        errorText={errors.id_jenis && touched.id_jenis && errors.id_jenis}
                      />

                      <CheckboxData />
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <SelectSearch
                        label="Satuan"
                        name="id_satuan_pakai"
                        placeholder="Pilih Satuan"
                        onChange={(val) => {
                          setFieldValue("id_satuan_pakai", val.value)
                          setFieldValue("id_satuan_beli", val.value)
                          setFieldValue("id_satuan_jual", val.value)
                        }}
                        option={satuanData.map((val) => Object({ value: val.id_satuan, label: val.nama_satuan }))}
                        defaultValue=""
                        error={errors.id_satuan_pakai && touched.id_satuan_pakai && true}
                        errorText={ errors.id_satuan_pakai && touched.id_satuan_pakai && errors.id_satuan_pakai } 
                      />

                      {/* <SelectSearch
                        label="Satuan Beli"
                        name="id_satuan_beli"
                        placeholder="Pilih Satuan Beli"
                        onChange={(val) => setFieldValue("id_satuan_beli", val.value)}
                        option={satuanData.map((val) => Object({ value: val.id_satuan, label: val.nama_satuan }) )}
                        defaultValue=""
                        error={errors.id_satuan_beli && touched.id_satuan_beli && true}
                        errorText={ errors.id_satuan_beli && touched.id_satuan_beli && errors.id_satuan_beli } 
                      /> */}

                      {/* <SelectSearch
                        label="Satuan Jual"
                        name="id_satuan_jual"
                        placeholder="Pilih Satuan Jual"
                        onChange={(val) => setFieldValue("id_satuan_jual", val.value)}
                        option={satuanData.map((val) => Object({ value: val.id_satuan, label: val.nama_satuan }))}
                        defaultValue=""
                        error={errors.id_satuan_jual && touched.id_satuan_jual && true}
                        errorText={ errors.id_satuan_jual && touched.id_satuan_jual && errors.id_satuan_jual } 
                      /> */}

                      <TextArea
                        label="Keterangan"
                        name="keterangan"
                        placeholder="Keterangan"
                        rows={4}
                        value={values.keterangan}
                        onChange={handleChange}
                        error={errors.keterangan && touched.keterangan && true}
                        errorText={errors.keterangan}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="primary"
                      text="Tambah"
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </div>
                </Modal.Footer>
              </div>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const [jenisData, setJenisData] = useState([]);
    const [isFetchingJenis, setIsFetchingJenis] = useState(false);

    const getDataJenis = (id) => {
      setIsFetchingJenis(true);
      const id_kelompok = id ?? updateData.id_kelompok;

      RegItemBahanApi.getJenis(id_kelompok)
        .then((res) =>
          setJenisData(
            res?.data?.data?.map((val) => Object({ value: val.id_jenis, label: val.nama_jenis }))
          )
        )
        .catch(() => setJenisData([]))
        .finally(() => setIsFetchingJenis(false));
    };

    useEffect(() => getDataJenis(), []);

    const formInitialValues = {
      id_buaso: updateData.id_buaso, // ID BUASO BAHAN
      kode_item: updateData.kode_item,
      nama_item: updateData.nama_item_pure,
      id_kelompok: updateData.id_kelompok,
      id_jenis: updateData.id_jenis,
      id_satuan_pakai: updateData.id_satuan_pakai,
      id_satuan_beli: updateData.id_satuan_beli,
      id_satuan_jual: updateData.id_satuan_jual,
      keterangan: updateData.keterangan,
      nama_item_pure: updateData.nama_item_pure,
      tipe: updateData.tipe,
      dimensi: updateData.dimensi,
      pj: updateData.pj,
      lb: updateData.lb,
      tb: updateData.tb,
      bisa_diproduksi: updateData.bisa_diproduksi,
      bisa_dijual: updateData.bisa_dijual,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        ...values,
        id_item_buaso: updateData.id_item_buaso,
        id_satuan: values.id_satuan_pakai,
      };

      RegItemBahanApi.update(finalValues)
        .then(() =>
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          })
        )
        .catch((err) =>
          setAlertConfig({
            variant: "danger",
            text: `Ubah data gagal! (${err.response.data.message})`,
          })
        )
        .finally(() => {
          setIsUpdateForm(false);
          setShowAlert(true);
          getData();
        });
    };

    console.log(updateData);

    return (
      <UpdateModal
        size="lg"
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title={title}
      >
        <Formik
          enableReinitializes
          initialValues={formInitialValues}
          // validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="container">
                <Modal.Body>
                  <div className="row ">
                    <div className="col-md-6 col-sm-12">
                      <Select
                        label="BUASO"
                        name="id_buaso"
                        onChange={handleChange}
                        errorText={errors.id_buaso}
                        defaultValue={values.id_buaso}
                        disabled={true}
                      >
                        {buasoData.map((e) => (
                          <option key={e.id_buaso} value={e.id_buaso}>
                            {e.kode_buaso} - {e.nama_buaso}
                          </option>
                        ))}
                      </Select>

                      <Input
                        label="Kode Barang"
                        type="text"
                        name="kode_item"
                        placeholder="Kode Item"
                        value={values.kode_item}
                        // readOnly={true}
                        onChange={handleChange}
                      />

                      <Input
                        label="Nama Item Barang"
                        type="text"
                        name="nama_item"
                        placeholder="Nama Item Barang"
                        value={values.nama_item}
                        onChange={(e) => setFieldValue("nama_item", e.target.value)}
                        error={errors.nama_item && touched.nama_item && true}
                        errorText={errors.nama_item && touched.nama_item && errors.nama_item}
                      />

                      <SelectSearch
                        label="Kelompok Barang"
                        name="id_kelompok"
                        placeholder="Pilih Kelompok"
                        onChange={(val) => {
                          setFieldValue("id_kelompok", val.value);
                          getDataJenis(val.value);
                        }}
                        option={kelompokData?.map((val) =>
                          Object({ value: val.id_kelompok, label: val.nama_kelompok })
                        )}
                        defaultValue={{
                          value: updateData.id_kelompok ?? "",
                          label:
                            updateData.id_kelompok === ""
                              ? "Pilih Kelompok"
                              : kelompokData.find(
                                  (val) => val.id_kelompok === updateData.id_kelompok
                                )?.nama_kelompok ?? "Pilih Kelompok",
                        }}
                        error={errors.id_kelompok && touched.id_kelompok && true}
                        errorText={errors.id_kelompok && touched.id_kelompok && errors.id_kelompok}
                      />

                      <SelectSearch
                        label="Jenis Barang"
                        name="id_jenis"
                        placeholder="Pilih Jenis"
                        onChange={(val) => setFieldValue("id_jenis", val.value)}
                        option={jenisData}
                        loading={isFetchingJenis}
                        defaultValue={
                          values.id_jenis
                            ? jenisData?.find((val) => val.value === values.id_jenis)
                            : ""
                        }
                        error={errors.id_jenis && touched.id_jenis && true}
                        errorText={errors.id_jenis && touched.id_jenis && errors.id_jenis}
                      />

                      <CheckboxData />
                    </div>

                    <div className="col-md-6 col-sm-12">
                      <SelectSearch
                        label="Satuan"
                        name="id_satuan_pakai"
                        placeholder="Pilih Satuan"
                        onChange={(val) => {
                          setFieldValue("id_satuan_pakai", val.value)
                          setFieldValue("id_satuan_beli", val.value)
                          setFieldValue("id_satuan_jual", val.value)
                        }}
                        option={satuanData.map((val) => Object({ value: val.id_satuan, label: val.nama_satuan }))}
                        defaultValue={{
                          value: updateData.id_satuan_pakai ?? "",
                          label:
                            updateData.id_satuan_pakai === ""
                              ? "Pilih Data Satuan Pakai"
                              : updateData.satuan_pakai,
                        }}
                        error={errors.id_satuan_pakai && touched.id_satuan_pakai && true}
                        errorText={errors.id_satuan_pakai && touched.id_satuan_pakai && errors.id_satuan_pakai}
                      />

                      {/* <SelectSearch
                        label="Satuan Beli"
                        name="id_satuan_beli"
                        placeholder="Pilih Satuan Beli"
                        onChange={(val) => setFieldValue("id_satuan_beli", val.value)}
                        option={satuanData.map((val) => Object({ value: val.id_satuan, label: val.nama_satuan }))}
                        defaultValue={{
                          value: updateData.id_satuan_beli ?? "",
                          label:
                            updateData.id_satuan_beli === ""
                              ? "Pilih Satuan"
                              : satuanData.find(
                                  (val) => val.id_satuan === updateData.id_satuan_beli
                                )?.nama_satuan ?? "Pilih Satuan",
                        }}
                        error={errors.id_satuan_beli && touched.id_satuan_beli && true}
                        errorText={errors.id_satuan_beli && touched.id_satuan_beli && errors.id_satuan_beli}
                      /> */}

                      {/* <SelectSearch
                        label="Satuan Jual"
                        name="id_satuan_jual"
                        placeholder="Pilih Satuan Jual"
                        onChange={(val) => setFieldValue("id_satuan_jual", val.value)}
                        option={satuanData.map((val) => Object({ value: val.id_satuan, label: val.nama_satuan }))}
                        defaultValue={{
                          value: updateData.id_satuan_jual ?? "",
                          label:
                            updateData.id_satuan_jual === ""
                              ? "Pilih Satuan"
                              : satuanData.find(
                                  (val) => val.id_satuan === updateData.id_satuan_jual
                                )?.nama_satuan ?? "Pilih Satuan",
                        }}
                        error={errors.id_satuan_jual && touched.id_satuan_jual && true}
                        errorText={errors.id_satuan_jual && touched.id_satuan_jual && errors.id_satuan_jual}
                      /> */}

                      <TextArea
                        label="Keterangan"
                        name="keterangan"
                        placeholder="Keterangan"
                        rows={4}
                        value={values.keterangan}
                        onChange={handleChange}
                        error={errors.keterangan && touched.keterangan && true}
                        errorText={errors.keterangan}
                      />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="d-flex justify-content-end">
                    <ActionButton
                      type="submit"
                      variant="success"
                      text="Ubah"
                      className="mt-2 px-4"
                      loading={isSubmitting}
                    />
                  </div>
                </Modal.Footer>
              </div>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL DETAIL COMPONENT
  const DetailModal = () => (
    <ReadModal size="lg" show={isReadForm} onHide={() => setIsReadForm(false)} title={title}>
      <Modal.Body>
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ListData label="BUASO">{updateData.id_buaso ?? "-"}</ListData>
            <ListData label="Kode Item Barang">{updateData.kode_item ?? "-"}</ListData>
            <ListData label="Nama Item Barang">{updateData.nama_item_pure ?? "-"}</ListData>
            <ListData label="Kelompok Barang">{updateData.nama_kelompok ?? "-"}</ListData>
            <ListData label="Jenis Barang">{updateData.nama_jenis ?? "-"}</ListData>
            <Checkbox label="Barang Bisa Diproduksi" checked={updateData.bisa_diproduksi} />

            <Checkbox label="Barang Bisa Dijual" checked={updateData.bisa_dijual} />
          </div>

          <div className="col-md-6 col-sm-12">
            <ListData label="Satuan">{updateData.satuan_pakai ?? "-"}</ListData>
            {/* <ListData label="Satuan Beli">{updateData.satuan_beli ?? "-"}</ListData>
            <ListData label="Satuan Beli">{updateData.satuan_jual ?? "-"}</ListData> */}
            <ListData label="Keterangan">{updateData.keterangan ?? "-"}</ListData>
          </div>
        </div>
      </Modal.Body>
    </ReadModal>
  );

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_item_buaso: deleteData.id_item_buaso };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      RegItemBahanApi.delete(deleteValue)
        .then(() =>
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          })
        )
        .catch((err) =>
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal!(${err.response.data.message})`,
          })
        )
        .finally(() => {
          setIsDeleteData(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Item Barang : {deleteData.kode_item}</div>
        <div>Nama Item Barang : {deleteData.nama_item}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Kode Item Barang</ThFixed>
              <Th>Nama Item Barang</Th>
              <Th>Kelompok Barang</Th>
              <Th>Jenis Barang</Th>
              <Th>Satuan Pakai</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <Td>
                    <div className="d-flex justify-content-center">
                      <ReadButton
                        onClick={() => {
                          setUpdateData(val);
                          setIsReadForm(true);
                        }}
                      />
                      {HeadOfficeStatus() && (
                        <>
                          <UpdateButton
                            onClick={() => {
                              setUpdateData(val);
                              setIsUpdateForm(true);
                            }}
                          />
                          <DeleteButton
                            onClick={() => {
                              setDeleteData(val);
                              setIsDeleteData(true);
                            }}
                          />

                          <Switch
                            id={val.id_item_buaso}
                            checked={val.is_hidden ? false : true}
                            onChange={() => changeDataStatus(val.is_hidden, val.id_item_buaso)}
                          />
                        </>
                      )}
                    </div>
                  </Td>
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_kelompok}</Td>
                  <Td>{val.nama_jenis}</Td>
                  <Td>{val.satuan_pakai}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>

        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            // eslint-disable-next-line
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((_res, index) => {
                    if (index === data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            // eslint-disable-next-line
            dataNumber={data.map((_res, index) => {
              if (index === 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          {HeadOfficeStatus() && (
            <CreateButton
              onClick={() => {
                setUpdateData({ id_item_buaso: 0 });
                setIsCreateForm(true);
              }}
            />
          )}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      {isCreateForm && <TambahModal />}
      {isUpdateForm && <UbahModal />}
      {isDeleteData && <HapusModal />}
      {isReadForm && <DetailModal />}
    </CRUDLayout>
  );
};

export default RegistrasiItemBahan;
