import Services from "services"

class TransferBahanJobMixApi {
    get(params) {
        return Services.get("/jobmix_transfer_bahan/page", { params })
    }

    getPageListPermintaan(params) {
        return Services.get("/jobmix_transfer_bahan/permintaan", { params })
    }

    getSinglePermintaan(params) {
        return Services.get("/jobmix_transfer_bahan/permintaan_single", { params })
    }

    getNomor(params) {
        return Services.get("/jobmix_transfer_bahan/no_baru", { params })
    }

    getDropdownGudang(params) {
        return Services.get("/dropdown/gudang", { params })
    }

    getDropdownBahan(params) {
        return Services.get("/dropdown/bahan", { params })
    }

    save(data) {
        return Services.post("/jobmix_transfer_bahan", data)
    }
}

export default new TransferBahanJobMixApi();
