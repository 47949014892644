import { DatePicker, SelectSearch, FilterModal } from 'components'
import { DateConvert } from 'utilities'
import { useFormik } from 'formik'

const ModalFilterPenerimaanBarang = ({ SESSION_KEY, dropdown, modalConfig, setModalConfig, data, setData }) => {
  const filter = data?.filter
  const formInitialValues = {
    tgl_penerimaan_barang_mulai: filter?.tgl_penerimaan_barang_mulai,
    tgl_penerimaan_barang_selesai: filter?.tgl_penerimaan_barang_selesai,
    tgl_purchase_order_mulai: filter?.tgl_purchase_order_mulai,
    tgl_purchase_order_selesai: filter?.tgl_purchase_order_selesai,
    vendor: filter?.vendor,
    item_barang: filter?.item_barang,
    status_approval: filter?.status_approval,
  }

  const formSubmitHandler = (values) => {
    const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

    if (checkActive) {
      setData({
        filter: { ...values, active: true },
        pagination: { ...data.pagination, page: 1 }
      })
    } else {
      setData({ ...data, filter: { ...values, active: false } })
    }
    setModalConfig(false)
  }

  const formik = useFormik({ initialValues: formInitialValues, onSubmit: formSubmitHandler })

  const { values, setFieldValue, setValues, handleSubmit } = formik

  const onChangeTglPenerimaan = (dates) => {
    const [start, end] = dates

    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({ ...values, tgl_penerimaan_barang_mulai: startDate, tgl_penerimaan_barang_selesai: endDate })
  }

  const onChangeTglPO = (dates) => {
    const [start, end] = dates

    const startDate = start ? DateConvert(start).default : null
    const endDate = end ? DateConvert(end).default : null

    setValues({ ...values, tgl_purchase_order_mulai: startDate, tgl_purchase_order_selesai: endDate })
  }

  const onResetButtonClick = () => {
    // DELETE FILTER & PAGINATION DI SESSION STORAGE
    sessionStorage.removeItem(SESSION_KEY.FILTER)
    sessionStorage.removeItem(SESSION_KEY.PAGINATION)

    setData(prev => ({
      ...prev,
      filter: {
        tgl_penerimaan_barang_mulai: undefined,
        tgl_penerimaan_barang_selesai: undefined,
        tgl_purchase_order_mulai: undefined,
        tgl_purchase_order_selesai: undefined,
        vendor: undefined,
        item_barang: undefined,
        status_approval: undefined,
      },
      pagination: {
        ...prev.pagination,
        page: 1,
        per_page: 10,
      }
    }))

    setModalConfig(false)
  }

  return (
    <FilterModal
      show={modalConfig?.show}
      setShow={setModalConfig}
      onResetButtonClick={() => onResetButtonClick()}
      onFilterButtonClick={handleSubmit}
    >
      <DatePicker
        selectsRange
        label="Tgl. Penerimaan Barang Barang"
        placeholderText="Pilih tanggal penerimaan barang"
        startDate={values?.tgl_penerimaan_barang_mulai ? new Date(values?.tgl_penerimaan_barang_mulai) : ''}
        endDate={values?.tgl_penerimaan_barang_selesai ? new Date(values?.tgl_penerimaan_barang_selesai) : ''}
        onChange={(dates) => onChangeTglPenerimaan(dates)}
        monthsShown={2}
      />
      <DatePicker
        selectsRange
        label="Tgl. Purchase Order"
        placeholderText="Pilih tanggal purchase order"
        startDate={values?.tgl_purchase_order_mulai ? new Date(values?.tgl_purchase_order_mulai) : ''}
        endDate={values?.tgl_purchase_order_selesai ? new Date(values?.tgl_purchase_order_selesai) : ''}
        onChange={(dates) => onChangeTglPO(dates)}
        monthsShown={2}
      />
      <SelectSearch
        key={values?.vendor}
        label="Vendor"
        placeholder="Pilih vendor"
        defaultValue={dropdown?.vendor?.find(item => item.value === values.vendor)}
        option={dropdown.vendor}
        onChange={val => setFieldValue('vendor', val.value)}
      />
      <SelectSearch
        key={values.item_barang}
        label="Item Barang"
        placeholder="Pilih item barang"
        defaultValue={dropdown?.item_barang?.find(item => item.value === values.item_barang)}
        option={dropdown.item_barang}
        onChange={val => setFieldValue('item_barang', val.value)}
      />
      <SelectSearch
        key={values.status_approval}
        label="Status Approval"
        placeholder="Pilih status approval"
        defaultValue={dropdown?.approval?.find(item => item.value === values.status_approval)}
        option={dropdown.approval}
        onChange={val => setFieldValue('status_approval', val.value)}
      />
    </FilterModal>
  )
}

export default ModalFilterPenerimaanBarang