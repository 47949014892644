const mappingData = (data, label) => {
    return data.data.data.map(val => {
        return {
            value: val[`id_${label}`],
            label: val[`nama_${label}`],
            ...val
        }
    })
}

export default mappingData