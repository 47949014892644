// import {
//   Row,
//   Col
// } from 'react-bootstrap'
// import {
//   DateConvert
// } from 'utilities'

// const InfoSection = ({dataInfo}) => {
//   const InfoItem = ({title, value}) => (
//     <tr>
//       <td width={125}>{title}</td>
//       <td className="pr-2">:</td>
//       <td>{value}</td>
//     </tr>
//   )

//   const InfoItemVertical = ({title, value}) => (
//     <div className="mb-2">
//       <small style={{fontSize: 12}} className="text-secondary">{title}</small>
//       <div style={{fontSize: 14}}>{value}</div>
//     </div>
//   )

//   return (
//     <>
//       <Row>
//         <Col md>
//           <table style={{fontSize: 14}}>
//             <tbody>
//               <InfoItem title="No. Sales Order" value={dataInfo.no_sales_order} />
//               <InfoItem title="No. Delivery Order" value={dataInfo.no_delivery_order} />
//               <InfoItem title="Gudang" value={dataInfo.gudang} />
//             </tbody>
//           </table>
//         </Col>
//         <Col md>
//           <table style={{fontSize: 14}}>
//             <tbody>
//               <InfoItem title="Customer" value={dataInfo.customer} />
//               <InfoItem title="Sales" value={dataInfo.sales} />
//               <InfoItem title="Tgl. Batas Waktu" value={dataInfo.tgl_batas_waktu ? DateConvert(new Date(dataInfo.tgl_batas_waktu)).detail : '-'} />
//             </tbody>
//           </table>
//         </Col>
//       </Row>
//       <hr />
//       <Row>
//         <Col md>
//           <InfoItemVertical 
//             title="Tgl. Surat Jalan"
//             value={dataInfo.tgl_surat_jalan ? DateConvert(new Date(dataInfo.tgl_surat_jalan)).detail : "-"}
//           />
//         </Col>
//         <Col md>
//           <InfoItemVertical 
//             title="No. Surat Jalan"
//             value={dataInfo.no_surat_jalan}
//           />
//         </Col>
//       </Row>
//       <Row>
//         <Col md>
//           <InfoItemVertical 
//             title="Petugas Pengiriman"
//             value={dataInfo.petugas_pengiriman}
//           />
//         </Col>
//         <Col md>
//           <InfoItemVertical 
//             title="Armada Pengiriman"
//             value={dataInfo.armada_pengiriman}
//           />
//         </Col>
//       </Row>
//     </>
//   )
// }

// export default InfoSection

// Component

import { Col, Row } from "react-bootstrap"
import { DateConvert } from "utilities"
import { InfoItemHorizontal } from "components"

const InfoSection = ({ data }) => {
  const alamat = `${data.alamat_pengiriman}${data?.customer?.nama_desa && ', Desa ' + data?.customer?.nama_desa}${data?.customer?.nama_kecamatan && ', Kec. ' + data?.customer?.nama_kecamatan}${data?.customer?.nama_kabupaten && ', ' + data?.customer?.nama_kabupaten}${data?.customer?.nama_provinsi && ', ' + data?.customer?.nama_provinsi}`

  return (
    <>
      {/* Sales Order */}
      <Row className="mb-2 mt-2">
        <Col>
          <InfoItemHorizontal minWidth={180} label="Tgl. Sales Order" text={DateConvert(new Date(data.tgl_sales_order)).detail} />
          <InfoItemHorizontal minWidth={180} label="No. Sales Order" text={data.no_sales_order ?? '-'} />
          <InfoItemHorizontal minWidth={180} label="Batas Waktu Pengiriman" text={data.batas_waktu ? DateConvert(new Date(data.batas_waktu)).detail : '-'} />
          <InfoItemHorizontal minWidth={180} label="Sales" text={data.nama_sales ?? '-'} />
          <InfoItemHorizontal minWidth={180} label="Customer" text={data.nama_customer ?? '-'} />
        </Col>
        <Col>
          <InfoItemHorizontal minWidth={180} label="Gudang" text={data.nama_gudang ?? '-'} />
          <InfoItemHorizontal minWidth={180} label="Alamat Pengiriman" text={alamat ?? '-'} />
          <InfoItemHorizontal minWidth={180} label="Catatan Sales Order" text={data.catatan ?? '-'} />
        </Col>
      </Row>
      <hr />

      {/* Delivery Order */}
      <Row>
        <Col>
          <InfoItemHorizontal minWidth={180} label="Tanggal Delivery Order" text={DateConvert(new Date(data.tgl_delivery_order)).detail} />
          <InfoItemHorizontal minWidth={180} label="No. Delivery Order" text={data.no_delivery_order} />
        </Col>
        <Col>
          <InfoItemHorizontal minWidth={180} label="Catatan Delivery Order" text={data.catatan_delivery_order} />
        </Col>
      </Row>
      <hr />

      {/* Surat Jalan */}
      <Row>
        <Col>
          <InfoItemHorizontal minWidth={180} label="Tanggal Surat Jalan" text={data.tgl_surat_jalan ? DateConvert(new Date(data.tgl_surat_jalan)).detail : "-"} />
          <InfoItemHorizontal minWidth={180} label="No. Surat Jalan" text={data.no_surat_jalan} />
        </Col>
        <Col>
          <InfoItemHorizontal minWidth={180} label="Petugas Pengiriman" text={data.nama_sopir} />
          <InfoItemHorizontal minWidth={180} label="Armada Pengiriman" text={data.nama_item_aset} />
        </Col>
      </Row>
    </>
  )
}

export default InfoSection