// Component
import {
  Table, Th, Td, DataStatus, TdFixed,
  ThFixed
} from 'components'

const TableSection = ({ dataTable }) => (
  <>
    <div className="py-1 mt-2">
      <b>List Barang</b>
    </div>
    <Table>
      <thead className="bg-light">
        <ThFixed>No</ThFixed>
        <ThFixed>Kode Barang</ThFixed>
        <Th>Item Barang</Th>
        <ThFixed>Satuan</ThFixed>
        <ThFixed>Qty. Surat Jalan</ThFixed>
      </thead>
      <tbody>
        {dataTable.length > 0
          ? dataTable.map((val, index) => (
            <tr key={index}>
              <TdFixed>{index + 1}</TdFixed>
              <TdFixed>{val.kode_item}</TdFixed>
              <Td>{val.nama_item}</Td>
              <Td>{val.satuan}</Td>
              <Td className="text-right">{val.qty_surat_jalan}</Td>
            </tr>
          ))
          : <tr>
            <td colSpan={8}>
              <DataStatus text="Tidak ada data" />
            </td>
          </tr>
        }
      </tbody>
    </Table>
  </>
)

export default TableSection