import React, { useState, useEffect } from 'react'
import { ReadModal, InfoItemHorizontal, DataStatus } from 'components'
import { Row, Col, Button, Modal } from "react-bootstrap"
import { PenerimaanGKGApi } from 'api'
import { RupiahConvert, DateConvert, DecimalConvert } from 'utilities'

const ModalDetail = ({ modalConfig, setModalConfig }) => {
    const { id_gkg } = modalConfig?.data ?? {}
    const [readData, setReadData] = useState([])
    const [loadingDetail, setLoadingDetail] = useState(false)

    const getDetailData = () => {
      setLoadingDetail(true)

      PenerimaanGKGApi.getSingle({ id_penerimaan_gkg: id_gkg })
      .then(res => setReadData(res?.data?.data))
      .catch(err => alert(err.response.data.message))
      .finally(() => setLoadingDetail(false))
    }
    useEffect(() => {
        getDetailData()
    }, [])

    const InfoItems = ({ label, value }) => {
        const separatorInfo = (value) => {
            const harga = value
            // const reverse = harga?.toString().split('').reverse().join('')
            // const ribuan = reverse?.match(/\d{1,3}/g)
            // const setRibuan	= ribuan?.join('.').split('').reverse().join('')
            // return setRibuan
            const number_string = harga.toString()
            const split = number_string.split(',')
            const sisa = split[0].length % 3
            const rupiah = split[0].substr(0, sisa)
            const ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);
		
            if (ribuan) {
                const separator = sisa ? '.' : '';
                rupiah += separator + ribuan.join('.');
            }
            return split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
        }
        return (
            <Col md={4}>
                <div className="pb-4">
                    <div style={{ fontSize: 14 }}>{label}</div>
                    <div><b>{`${DecimalConvert(value ?? 0).getWithComa} Kg`}</b></div>
                </div>
            </Col>
        )
    }
    const InfoRupiah = ({ label, value }) => (
        <Col md={4}>
            <div className="pb-4">
                <div style={{ fontSize: 14 }}>{label}</div>
                <div><b>{RupiahConvert(value).getWithComa}</b></div>
            </div>
        </Col>
    )
    const ItemInfo = ({ label, value }) => (
        <Col md={4}>
            <div className="pb-4">
                <div style={{ fontSize: 14 }}>{label}</div>
                <div><b>{value}</b></div>
            </div>
        </Col>
    )
    const InfoItem = ({ label, value }) => (
    <Col md={4}>
        <div>
            <div style={{ fontSize: 14 }}>{label}</div>
            <div><b>{value}</b></div>
        </div>
    </Col>
    )
    // const InfoItemHorizontalDate = ({ label, text, width, minWidth = 120, style, className }) => (
    //     <div className={`d-flex align-items-top ${className}`} style={style}>
    //       <div style={{ width: width ? width : 180, minWidth: minWidth, fontSize: 14 }}>
    //         {label}
    //       </div>
    //       <div className="pl-3 pr-2" style={{ fontSize: 14 }}>:</div>
    //       <div style={{ fontSize: 14 }}>{DateConvert(text).detail}</div>
    //     </div>
    // )

    return (
        <ReadModal
            size="lg"
            show={modalConfig?.show && modalConfig?.type === "detail"}
            onHide={() => setModalConfig({ ...modalConfig, show: false, type: 'detail' })}
            title="Penerimaan Gabah Kering Giling (GKG)">
            
            <Modal.Body>
                { loadingDetail === true
                    ? <DataStatus loading={loadingDetail} text="Memuat data..." />
                    : <>
                        <div>
                            <InfoItemHorizontal label="Tgl. Penerimaan GKG" text={readData?.tgl_penerimaan_gkg ? DateConvert(new Date(readData?.tgl_penerimaan_gkg)).detail : "-"} />
                            <InfoItemHorizontal label="No Penerimaan GKG" text={readData?.no_penerimaan_gkg ?? "-"} />
                            <InfoItemHorizontal label="Nama Item" text={readData?.nama_item ?? "-"} />
                            <InfoItemHorizontal label="Kode Item" text={readData?.kode_item ?? "-"} />
                            <hr /> 
                        </div>
                        <Row>
                            <InfoItems label="Berat Truk Masuk" value={readData?.berat_truk_masuk ?? "-"} />
                            <InfoItems label="Berat Truk Keluar" value={readData?.berat_truk_keluar ?? "-"} />
                            <InfoItems label="Berat Gabah Kering Giling (GKG)" value={readData?.berat_gabah_kering_giling ?? "-"} />
                        </Row>
                        <Row>
                            <InfoRupiah label="Harga Satuan" value={readData?.harga_satuan ?? "-"} />
                            <InfoRupiah label="Total Harga" value={readData?.total_harga ?? "-"} />
                            <ItemInfo label="supir" value={readData?.supir ?? "-"} />
                        </Row>
                        <Row>
                            <ItemInfo label="Plat Kendaraan" value={readData?.plat_kendaraan ?? "-"} />
                            <ItemInfo label="Gudang Penerimaan" value={readData?.nama_gudang ?? "-"} />
                        </Row>
                        <Row>
                            <InfoItem label="Keterangan" value={readData?.keterangan ?? "-"} />
                        </Row>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={() => setModalConfig({ ...modalConfig, show: false })}>TUTUP</Button>
            </Modal.Footer>
        </ReadModal>
    )
}

export default ModalDetail