import Services from "../../../services";

class PabrikanApi {
  getPage(page, dataLength, key) {
    return Services.get(`/pabrikan/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  generateKodeItem() {
    return Services.get("/pabrikan/no_baru");
  }
  create(data) {
    return Services.post("/pabrikan", data);
  }
  update(data) {
    return Services.put("/pabrikan", data);
  }
  delete(id_pabrikan) {
    return Services.post("/pabrikan/delete", id_pabrikan);
  }
  search(key) {
    return Services.get(`pabrikan/page/?q=${key}`);
  }
  show(id_pabrikan) {
    return Services.put("/pabrikan/show", id_pabrikan);
  }
  hide(id_pabrikan) {
    return Services.put("/pabrikan/hide", id_pabrikan);
  }
}

export default new PabrikanApi();
