import Services from "../../../services";

class PurchaseRequestApi {
  // getPage(page, dataLength, key) {
  //   return Services.get(`/purchase_request/page?page=${page}&per_page=${dataLength}&q=${key}`);
  // }
  getPage(params) {
    return Services.get(`/purchase_request/page`, { params });
  }

  single(id) {
    return Services.get("/purchase_request/single?id_purchase_request=" + id);
  }

  getNoSurat(id) {
    return Services.get(`/purchase_request/no_baru?tanggal=${id}`);
  }

  getKelompok() {
    return Services.get("/purchase_request/kelompok_bahan?id_buaso=1");
  }

  getJenis(id) {
    return Services.get(`/purchase_request/jenis_bahan?id_kelompok=${id}`);
  }

  getItem(id) {
    return Services.get(`/purchase_request/item_bahan?id_jenis=${id}`);
  }

  getProgress(id) {
    return Services.get(`purchase_request/progress?id_purchase_request=${id}`);
  }

  search(key) {
    return Services.get(`/purchase_request/page/?q=${key}`);
  }

  create(data) {
    return Services.post("/purchase_request", data);
  }

  update(data) {
    return Services.put("/purchase_request", data);
  }

  delete(id) {
    return Services.post("/purchase_request/delete", id);
  }
}

export default new PurchaseRequestApi();
