import Services from "../../../services";

class BuasoApi {
  getPage(params) {
    return Services.get("/depo/page", {params});
  }
  getKode() {
      return Services.get("/depo/no_baru")
  }
  dropdownUnitOrganisasi() {
      return Services.get("/unit_organisasi/dropdown")
  }
  dropdownPenanggungJawab() {
      return Services.get("/karyawan/dropdown")
  }
  create(value) {
    return Services.post("/depo", value);
  }
  update(value) {
    return Services.put("/depo", value);
  }
  delete(id) {
    return Services.post("/depo/delete", id);
  }
  checkDuplicateNama(params) {
    return Services.get("/depo/single_nama/", {params})
  }
}

export default new BuasoApi();
