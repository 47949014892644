import {
  useState,
  useEffect
} from 'react'
import {
  Row,
  Col
} from 'react-bootstrap'
import {
  DatePicker,
  Input,
  SelectSearch
} from 'components'
import {
  DateConvert,
} from 'utilities'
import {
  TransferBahanJobMixApi
} from 'api'

const FormSection = ({ formik, dropdownGudang, dropdownBahan, dataInfo }) => {
  const today = DateConvert(new Date()).default
  const [isNomorLoading, setIsNomorLoading] = useState(false)
  const { values, errors, touched, setValues, setFieldValue } = formik

  const dropdownGudangTujuan = dropdownGudang.filter(item => item.id_pabrik === values.id_pabrik) // Filter dropdown gudang berdasarkan id_pabrik yang membuat permintaan
  const dropdwonBahanSemen = dropdownBahan.filter(item => (item.id_item_buaso !== "421") && (item.id_jenis === '3')) // Filter Dropdown Semen kecuali Semen Produksi, id_item_buaso = 421

  const defaultDropdownBahan = (values.id_item_buaso === "") ? dropdwonBahanSemen : dropdownBahan
  const isDisableItemBahan = dataInfo.id_item_permintaan != '421'

  const getNomorHandler = date => {
    setIsNomorLoading(true)

    TransferBahanJobMixApi.getNomor({ tanggal: date })
      .then(no => {
        const nomor = no.data.data

        setValues({
          ...values,
          nomor: nomor,
          tanggal: date
        })
      })
      .catch(() => {
        setValues({
          ...values,
          nomor: "",
          tanggal: ""
        })
        window.alert("Nomor gagal dimuat")
      })
      .finally(() => {
        setIsNomorLoading(false)
      })
  }

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "");
    const convert = newValue.substring(0, 7);

    return convert;
  };

  useEffect(() => {
    getNomorHandler(today)
    return () => setIsNomorLoading(false)
  }, [])

  return (
    <div>
      <Row>
        <Col md>
          <DatePicker
            label="Tgl. Transfer Bahan Job Mix"
            placeholderText="Pilih tanggal"
            selected={values.tanggal ? new Date(values.tanggal) : ""}
            onChange={date => {
              const newDate = DateConvert(date).default
              getNomorHandler(newDate)
            }}
            error={Boolean(errors.tanggal) && touched.tanggal}
            errorText={Boolean(errors.tanggal) && touched.tanggal && errors.tanggal}
          />
        </Col>
        <Col md>
          <Input
            readOnly
            label="No. Transfer Bahan Job Mix"
            placeholder={isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"}
            value={isNomorLoading ? "Memuat nomor . . ." : values.nomor}
            error={Boolean(errors.nomor) && touched.nomor}
            errorText={Boolean(errors.nomor) && touched.nomor && errors.petugas_pengiriman}
          />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <Input
            readOnly
            label="Kelompok Bahan"
            value={values.nama_kelompok}
          />
        </Col>
        <Col md={3}>
          <Input
            readOnly
            label="Jenis Bahan"
            value={values.nama_jenis}
          />
        </Col>
        <Col md>
          <SelectSearch
            isDisabled={isDisableItemBahan}
            label="Item Bahan"
            placeholder="Pilih item bahan"
            option={defaultDropdownBahan}
            defaultValue={defaultDropdownBahan.find(val => val.value === values.id_item_buaso)}
            onChange={val => {
              setFieldValue('id_item_buaso', val.value)
              setFieldValue('id_satuan_transfer', val.id_satuan)
              setFieldValue('satuan', val.nama_satuan)
            }}
            error={Boolean(errors.id_item_buaso) && touched.id_item_buaso}
            errorText={Boolean(errors.id_item_buaso) && touched.id_item_buaso && errors.id_item_buaso}
          />
        </Col>
      </Row>

      <Row>
        <Col md>
          <SelectSearch
            label="Gudang Asal"
            placeholder="Pilih gudang asal"
            // option={dropdownGudang}
            option={dropdownGudang.map(val => Object({ ...val, label: `${val.nama_gudang} (${val.jumlah ?? 0} ${val.nama_satuan ?? ''})` }))}
            defaultValue={dropdownGudang.find(val => val.value === values.id_gudang_asal)}
            onChange={val => {
              setFieldValue('id_gudang_asal', val.value)
              setFieldValue('qty_stok', val.jumlah)
            }}
            error={Boolean(errors.id_gudang_asal) && touched.id_gudang_asal}
            errorText={Boolean(errors.id_gudang_asal) && touched.id_gudang_asal && errors.id_gudang_asal}
          />
        </Col>
        <Col md={3}>
          <Input
            readOnly
            label="Qty. Stok"
            placeholder="Qty. Stok"
            value={values.qty_stok}
          />
        </Col>
        <Col md={3}>
          <Input
            readOnly
            label="Satuan Stok"
            value={values.satuan}
          />
        </Col>
      </Row>
      <Row>
        <Col md>
          <SelectSearch
            label="Gudang Tujuan"
            placeholder="Pilih gudang tujuan"
            option={dropdownGudangTujuan}
            defaultValue={dropdownGudangTujuan.find(val => val.value === values.id_gudang_tujuan)}
            onChange={val => setFieldValue('id_gudang_tujuan', val.value)}
            error={Boolean(errors.id_gudang_tujuan) && touched.id_gudang_tujuan}
            errorText={Boolean(errors.id_gudang_tujuan) && touched.id_gudang_tujuan && errors.id_gudang_tujuan}
          />
        </Col>
        <Col md={3}>
          <Input
            label="Qty. Transfer"
            placeholder="Masukkan qty. transfer"
            value={values.qty_transfer}
            onChange={(val) => {
              const value = decimalConvert(val.target.value)
              setFieldValue('qty_transfer', value)
            }}
            onBlur={() => { setFieldValue('qty_transfer', Number(values.qty_transfer).toFixed(2)) }}
            error={Boolean(errors.qty_transfer) && touched.qty_transfer}
            errorText={Boolean(errors.qty_transfer) && touched.qty_transfer && errors.qty_transfer}
          />
        </Col>
        <Col md={3}>
          <Input
            readOnly
            label="Satuan Transfer"
            value={values.satuan}
          />
        </Col>
      </Row>
    </div>
  )
}

export default FormSection