import Services from "services";

class PermintaanBahanProduksiApi {
  // Page
  page(value) {
    return Services.get('/transfer_produksi_bahan/page', {params: {...value}});
  }

  single(value) {
    return Services.get('/transfer_produksi_bahan/single', {params: {...value}});
  }

  transfer(value) {
    return Services.get('/transfer_produksi_bahan/transfer', {params: {...value}});
  }

  no_baru(value) {
    return Services.get('/transfer_produksi_bahan/no_baru', {params: {...value}});
  }

  dropdown(value) {
    return Services.get('/transfer_produksi_bahan/dropdown', {params: {...value}});
  }

  create(value) {
    return Services.post('/transfer_produksi_bahan', value);
  }
  
  update(value) {
    return Services.put('/transfer_produksi_bahan', value);
  }

  delete(value) {
    return Services.post('/transfer_produksi_bahan/delete', value);
  }
  
  qty_booking(value) {
    return Services.get('/transfer_produksi_bahan/qty_booking', {params: {...value}});
  }

  // Cetak
  getHistoryCetak(params) {
    return Services.get('/transfer_produksi_bahan_cetak', {params})
  }

  getDetailCetak(params) {
    return Services.get('/transfer_produksi_bahan_cetak/single', {params})
  }

  getNomorCetak(params) {
    return Services.get('/transfer_produksi_bahan_cetak/no_baru', {params})
  }

  saveCetak(data) {
    return Services.post('transfer_produksi_bahan_cetak', data)
  }
}

export default new PermintaanBahanProduksiApi();
