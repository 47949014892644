import {
  Table,
  Th,
  Td,
  DataStatus,
  TdFixed,
  ThFixed
} from 'components'
import { DateConvert } from 'utilities'

const TableSection = ({ dataTable }) => (
  <>
    <div className="p-1">
      <b>Histori Transfer Bahan Job Mix</b>
    </div>
    <Table>
      <thead className="bg-light">
        <Th>No.</Th>
        <ThFixed>Tgl. Transfer Bahan</ThFixed>
        <Th>No. Transfer Bahan</Th>
        <Th>Item Bahan</Th>
        <ThFixed>Qty. Transfer Bahan</ThFixed>
        <Th>Gudang Asal</Th>
        <Th>Gudang Tujuan</Th>
      </thead>
      <tbody>
        {dataTable.length > 0
          ? dataTable.map((val, index) => (
            <tr key={index}>
              <TdFixed>{index + 1}</TdFixed>
              <Td>{val.tgl_jobmix_transfer_bahan ? DateConvert(new Date(val.tgl_jobmix_transfer_bahan)).defaultDMY : "-"}</Td>
              <Td>{val.no_jobmix_transfer_bahan ?? "-"}</Td>
              <Td>{val.item_bahan}</Td>
              <Td className="text-right">{val.qty_transfer_bahan}</Td>
              <Td>{val.nama_gudang_asal ?? "-"}</Td>
              <Td>{val.nama_gudang_tujuan ?? "-"}</Td>
            </tr>
          ))
          : <tr>
            <td colSpan={8}>
              <DataStatus text="Tidak ada data" />
            </td>
          </tr>
        }
      </tbody>
    </Table>
  </>
)

export default TableSection