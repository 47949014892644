import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  DeleteButton,
  UpdateButton,
  ReadButton,
  DataStatus,
  Alert,
  DeleteModal,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  BackButton,
} from "../../../../components";
import { PengembalianBarangApi } from "../../../../api";
import { useHistory } from "react-router-dom";
import { DateConvert } from "../../../../utilities";
import { Modal } from "react-bootstrap";
import { IoReload, IoCloseCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import { PageNumber } from "../../../../utilities";
// import { PrintDeliveryOrder } from "./PrintDeliveryOrder";

const PengembalianBarang = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // menangani modal hapus data
  const [isDeleteData, setIsDeleteData] = useState(false);

  const history = useHistory();
  const location = useLocation();
  let createAlert = location.state;

  // data
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // menampung data yang akan dihapus
  const [deleteData, setDeleteData] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  const [modalStatus, setModalStatus] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  // const [id, setId] = useState("");
  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    Axios.all([PengembalianBarangApi.getPage(page, dataLength)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  // request search data dari server
  const searchData = (e) => {
    // mencegah terjadinya page reload
    e.preventDefault();
    setIsLoading(true);
    // nilai dari form search
    const key = searchKey;
    // lakukan search data ke server pada kode dibawah [
    PengembalianBarangApi.search(key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        });
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
      })
      .finally(() => {
        setIsSearching(true);
        setIsLoading(false);
        setShowAlert(true);
      });
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Pengembalian Barang");
    if (createAlert !== undefined) {
      setShowAlert(true);
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text });

      history.replace((createAlert = undefined));
    } else {
      return getData();
    }
    getData();
    return () => {
      setIsLoading(false);
      setIsSearching(false);
      setShowAlert(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);
  // Modal Hapus
  const HapusModal = () => {
    // id dari data yang ingin dihapus
    const idData = deleteData.id_delivery_order;
    const value = { id_delivery_order: idData };
    // menangani delete button loading
    const [btnLoading, setBtnLoading] = useState(false);

    // request hapus data ke server

    const deleteDataHandler = () => {
      setBtnLoading(true);
      PengembalianBarangApi.delete(value)
        .then(() => {
          setAlertConfig({
            variant: "primary",
            text: "Hapus data berhasil!",
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: "danger",
            text: `Hapus data gagal! (${err?.response?.data?.message})`,
          });
        })
        .finally(() => {
          // menampilkan alert
          setShowAlert(true);
          // menutup hapus modal
          setIsDeleteData(false);
          // request data baru ke server
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title="Pengembalian Barang"
      >
        <div>No. Pengembalian Barang : {deleteData.no_pengembalian_barang}</div>
        <div>Nama Customer : {deleteData.nama_customer}</div>
      </DeleteModal>
    );
  };

  const ModalStatus = () => {
    return (
      <>
        <Modal
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-info">
              {modalStatus === "1" ? (
                <h6 className="mb-0 text-info">
                  <IoReload className="mb-1 mr-2" size={20} />
                  Progres (Status ON PROGRES)
                </h6>
              ) : modalStatus === "2" ? (
                <h6 className="mb-0 text-success">
                  <IoCheckmarkCircleOutline className="mb-1 mr-2" size={20} />
                  Progress (status DONE)
                </h6>
              ) : (
                <h6 className="mb-0 text-danger">
                  <IoCloseCircleOutline className="mb-1 mr-2" size={20} />
                  Progress (status CANCELED)
                </h6>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CRUDLayout.Table>
              <THead>
                <Tr className="text-center">
                  <ThFixed>No</ThFixed>
                  <Th>Nama proses</Th>
                  <Th>Tanggal proses</Th>
                  <Th>No dokumen</Th>
                </Tr>
              </THead>
              <TBody>
                <Tr>
                  <TdFixed>1</TdFixed>
                  <Td>Sales Order (SO)</Td>
                  <Td>25/05/2021</Td>
                  <Td>2021/05/MKT/SO/00001</Td>
                </Tr>
              </TBody>
            </CRUDLayout.Table>
            <div className="d-flex justify-content-end">
              <BackButton
                onClick={() => {
                  setModalShow(false);
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Tanggal pengembalian barang</Th>
              <ThFixed>No Pengembalian Barang</ThFixed>
              <ThFixed>No Pembatalan Faktur</ThFixed>
              <ThFixed>No faktur penjualan</ThFixed>
              {/* <Th>No delivery order</Th> */}
              <Th>Customer</Th>
              <Th>Sales</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      onClick={() => {
                        history.push(
                          `/inventory/transaksi/pengembalian-barang/detail-pengembalian-barang/${val.id_pengembalian_barang}`
                        );
                      }}
                    />
                    <UpdateButton
                      onClick={() =>
                        history.push(
                          `/inventory/transaksi/pengembalian-barang/edit-pengembalian-barang/${val.id_pengembalian_barang}`
                        )
                      }
                    />
                    <DeleteButton
                      onClick={() => {
                        setDeleteData(val);
                        setIsDeleteData(true);
                      }}
                    />
                  </div>
                </TdFixed>
                <Td>{DateConvert(new Date(val.tgl_pengembalian_barang)).defaultDMY}</Td>
                <TdFixed>{val.no_pengembalian_barang}</TdFixed>
                <TdFixed>{val.no_pembatalan_faktur}</TdFixed>
                {/* <Td>{val.no_faktur}</Td> */}
                <TdFixed>{val.no_delivery_order}</TdFixed>
                <Td>{val.nama_customer}</Td>
                <Td>{val.nama_karyawan}</Td>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataNumber={page * dataLength - dataLength + 1}
            dataCount={totalData}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
            onDataLengthChange={(e) => {
              setPage(1);
              setDataLength(e.target.value);
            }}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push("/inventory/transaksi/pengembalian-barang/faktur-dibatalkan");
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}
      <HapusModal />
      <ModalStatus />
    </CRUDLayout>
  );
};

export default PengembalianBarang;
