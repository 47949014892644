import React, {useState, useEffect} from 'react'
import { Formik } from 'formik'
import {
    FilterModal,
    DatePicker,
    SelectSearch
} from 'components'
import { DateConvert } from 'utilities'
import { PenerimaanGKGApi } from "api";

const ModalFilter = ({ show, setShow, data, setData }) => {
// STATE DATA
    const [loading, setLoading] = useState(true)
    const [dropdownGKG, setDropdownGKG] = useState([{label: 'Semua', value: undefined}])
// REQUSET DATA SERVER 
    const getDataDropdown = () => {
        PenerimaanGKGApi.getDropdownGKG()
        .then(gkg => {
            const mapDataGKG = gkg?.data?.data ? gkg.data.data.map(val => ({ label: val?.nama_gudang, value: val?.id_gudang })) : []
            setDropdownGKG([...dropdownGKG, ...mapDataGKG])
        })
        .finally(() => setLoading(false))
    }
// USE EFFECT ALL DROPDOWN
    useEffect(() => {
        getDataDropdown()
    }, [])
// FORM VALUES
    const formInitialValues = {
        tgl_penerimaan_gkg_mulai: data?.filter?.tgl_penerimaan_gkg_mulai,
        tgl_penerimaan_gkg_selesai: data?.filter?.tgl_penerimaan_gkg_selesai,
        gudang: data?.filter?.gudang,
    }
    const formSubmitHandler = (values) => {
        const checkActive = !!Object.values(values).filter(val => val !== undefined).length > 0

        if (checkActive) {
          setData({ 
              filter: {
                  ...values,
                  active: true
              },
              pagination: {
                  ...data.pagination,
                  page: 1
              }
           })
        } else { 
            setData({ 
                ...data,
                filter: {
                    ...values,
                    active: false
                }
             })
        }
    
        setShow(false)
    }
// HANDLE CHANGE 
    const onTanggalGKGChange = (dates, values, setValues) => {
        const [start, end] = dates
        const startDate = start ? DateConvert(start).default : null
        const endDate = end ? DateConvert(end).default : null

        setValues({
            ...values,
            tgl_penerimaan_gkg_mulai: startDate,
            tgl_penerimaan_gkg_selesai: endDate,
        })
    }
    const onResetButtonClick = (values, setValues) => {
        setValues({
            tgl_penerimaan_gkg_mulai: undefined,
            tgl_penerimaan_gkg_selesai: undefined,
            gudang: undefined,
        })
    }

    return (
        <Formik
            enableReinitialize
            initialValues={formInitialValues}
            onSubmit={formSubmitHandler}>
            
            {({values, handleSubmit, setFieldValue, setValues}) => (
                <FilterModal
                    show={show}
                    setShow={setShow}
                    onResetButtonClick={() => onResetButtonClick(values, setValues)}
                    onFilterButtonClick={handleSubmit}>
                    
                    <DatePicker 
                        selectsRange
                        label="Tgl. Penerimaan Gabah Kering Giling (GKG)"
                        placeholderText="Pilih Penerimaan Gabah Kering Giling"
                        dateFormat="dd/MM/yyyy"
                        startDate={values.tgl_penerimaan_gkg_mulai ? new Date(values.tgl_penerimaan_gkg_mulai) : ''}
                        endDate={values.tgl_penerimaan_gkg_selesai ? new Date(values.tgl_penerimaan_gkg_selesai) : ''}
                        onChange={dates => onTanggalGKGChange(dates, values, setValues)}
                        monthsShown={2}
                    />
                    <SelectSearch 
                        key={values.gudang}
                        label="Gudang Penerimaan"
                        placeholder="Pilih Gudang Penerimaan"
                        defaultValue={dropdownGKG.find(val => val.value === values.gudang)}
                        option={dropdownGKG}
                        onChange={val => setFieldValue('gudang', val.value)}
                        loading={loading}
                    />
                </FilterModal>
            )}
        </Formik>
    )
}
export default ModalFilter;