import React, { useState, useEffect } from "react"
import {
  Modal
} from "react-bootstrap"
import {
  Formik
} from "formik"
import * as Yup from "yup"
import Axios from "axios"
import {
  CRUDLayout,
  Input,
  InputSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  ReadModal,
  UpdateButton,
  DeleteButton,
  ReadButton,
  Switch,
  Alert,
  Pagination,
  THead,
  TBody,
  ThFixed,
  TdFixed,
  Tr,
  Th,
  Td,
  ListData
} from "components"
import {
  PageNumber,
  TableNumber,
  HeadOfficeStatus
} from "utilities"
import {
  SatuanApi
} from "api"

const Satuan = ({ setNavbarTitle }) => {
  // Title
  const title = "Satuan"

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true)

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false)
  const [searchKey, setSearchKey] = useState("")

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false) // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false) // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false) // MODAL HAPUS STATE
  const [isReadForm, setIsReadForm] = useState(false) // MODAL DETAIL STATE

  // STATE DATA SATUAN
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [dataLength, setDataLength] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState("")
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([])
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([])

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false)
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true)
    setIsSearching(false)

    setShowAlert(false)

    Axios.all([SatuanApi.getPage(page, dataLength, searchKey)])
      .then(
        Axios.spread((res) => {
          setData(res.data.data)
          setTotalPage(res.data.total_page)
          setTotalData(res.data.data_count)
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          })
          setShowAlert(true)
        }
        setIsLoading(false)
      })
  }

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true)
    setShowAlert(false)

    const value = {
      id_satuan: id,
    }

    const onLoadedSuccess = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      })
      setShowAlert(true)
    }

    const onLoadedFailed = () => {
      setIsSearching(false)
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      })
      setShowAlert(true)
    }

    status === true
      ? SatuanApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : SatuanApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
  }

  // FUNCTION CARI DATA DARI SERVER
  const searchData = (e) => {
    e.preventDefault()
    setIsLoading(true)

    const key = searchKey // SearchKey Value as key

    // SEARCH DATA WITH KEY FROM DATABASE
    SatuanApi.search(key)
      .then((res) => {
        setData(res.data.data)
        setTotalPage(res.data.total_page)
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsSearching(true)
        setIsLoading(false)
        setAlertConfig({
          variant: "primary",
          text: `Hasil Pencarian : ${key}`,
        })
        setShowAlert(true)
      })
  }

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title)
    getData()

    return () => {
      setIsLoading(false)
      setIsSearching(false)
    }
  }, [setNavbarTitle, page, dataLength, searchKey])

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    kode_satuan: Yup.string().required("Masukkan kode satuan"),
    nama_satuan: Yup.string().required("Masukkan nama satuan"),
    // keterangan: Yup.string().required("Masukkan keterangan"),
  })

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    const formInitialValues = {
      kode_satuan: "",
      nama_satuan: "",
      keterangan: "",
    }

    const formSubmitHandler = (values) => {
      const finalValues = {
        ...values,
        status: "Tampil", // SET DEFAULT STATUS AS "Tampil"
      }

      SatuanApi.create(finalValues)
        .then((res) => {
          setAlertConfig({
            variant: "primary",
            text: "Tambah data berhasil!",
          })
        })
        .catch((err) => setAlertConfig({
          variant: "danger",
          text: `Tambah data gagal! (${err.response.data.message})`
        }))
        .finally(() => {
          setIsCreateForm(false)
          setShowAlert(true)
          getData()
        })
    }

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Satuan"
                  type="text"
                  name="kode_satuan"
                  placeholder="Kode Satuan"
                  value={values.kode_satuan}
                  onChange={handleChange}
                  error={errors.kode_satuan && touched.kode_satuan && true}
                  errorText={errors.kode_satuan}
                />

                <Input
                  label="Nama Satuan"
                  type="text"
                  name="nama_satuan"
                  placeholder="Nama Satuan"
                  value={values.nama_satuan}
                  onChange={handleChange}
                  error={errors.nama_satuan && touched.nama_satuan && true}
                  errorText={errors.nama_satuan}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />
              </Modal.Body>

              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    )
  }

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      kode_satuan: updateData.kode_satuan,
      nama_satuan: updateData.nama_satuan,
      keterangan: updateData.keterangan,
    }

    const formSubmitHandler = (values) => {
      const finalValues = {
        id_satuan: updateData.id_satuan,
        ...values,
      }

      SatuanApi.update(finalValues)
        .then((res) =>
          setAlertConfig({
            variant: "primary",
            text: "Ubah data berhasil!",
          }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          text: `Ubah data gagal! (${err.response.data.message})`
        }))
        .finally(() => {
          setIsUpdateForm(false)
          setShowAlert(true)
          getData()
        })
    }

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Input
                  label="Kode Satuan"
                  type="text"
                  name="kode_satuan"
                  placeholder="Kode Satuan"
                  value={values.kode_satuan}
                  // readOnly={true}
                  onChange={handleChange}
                  error={errors.kode_satuan && touched.kode_satuan && true}
                  errorText={errors.kode_satuan}
                />

                <Input
                  label="Nama Satuan"
                  type="text"
                  name="nama_satuan"
                  placeholder="Nama Satuan"
                  value={values.nama_satuan}
                  onChange={handleChange}
                  error={errors.nama_satuan && touched.nama_satuan && true}
                  errorText={errors.nama_satuan}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    )
  }

  // MODAL UBAH COMPONENT
  const DetailModal = () => (
    <ReadModal show={isReadForm} onHide={() => setIsReadForm(false)} title={title}>
      <Modal.Body>
        <ListData label="Kode Satuan">{updateData.kode_satuan ?? '-'}</ListData>
        <ListData label="Nama Satuan">{updateData.nama_satuan ?? '-'}</ListData>
        <ListData label="Keterangan">{updateData.keterangan ?? '-'}</ListData>
      </Modal.Body>
    </ReadModal>
  )

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_satuan: deleteData.id_satuan }

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false)

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true)

      SatuanApi.delete(deleteValue)
        .then(() => setAlertConfig({
          variant: "primary",
          text: "Hapus data berhasil!",
        }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          text: `Hapus data gagal! (${err.response.data.message})`,
        }))
        .finally(() => {
          setIsDeleteData(false)
          setShowAlert(true)
          getData()
        })
    }

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Satuan : {deleteData.kode_satuan}</div>
        <div>Nama Satuan : {deleteData.nama_satuan}</div>
      </DeleteModal>
    )
  }

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Kode Satuan</Th>
              <Th>Nama Satuan</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_satuan}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ReadButton
                        onClick={() => {
                          setUpdateData(val)
                          setIsReadForm(true)
                        }}
                      />
                      {HeadOfficeStatus() && (
                        <>
                          <UpdateButton
                            onClick={() => {
                              setUpdateData(val)
                              setIsUpdateForm(true)
                            }}
                          />
                          <DeleteButton
                            onClick={() => {
                              setDeleteData(val)
                              setIsDeleteData(true)
                            }}
                          />
                          <Switch
                            id={val.id_satuan}
                            checked={val.is_hidden ? false : true}
                            onChange={() => changeDataStatus(val.is_hidden, val.id_satuan)}
                          />
                        </>
                      )}
                    </div>
                  </TdFixed>
                  <Td>{val.kode_satuan}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td>{val.keterangan}</Td>
                </Tr>
              )
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                  if (index == data.length - 1) {
                    return TableNumber(page, dataLength, index)
                  }
                })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index)
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value)
              setPage(1)
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value)
                  setPage(1)
                }, 1000)
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          {HeadOfficeStatus() && <CreateButton onClick={() => setIsCreateForm(true)} />}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
      <DetailModal />
    </CRUDLayout>
  )
}

export default Satuan
