import {
  Row,
  Col
} from 'react-bootstrap'
import { 
  DateConvert 
} from 'utilities'

const InfoSection = ({dataInfo}) => {
  const InfoItem = ({title, value}) => (
    <tr>
      <td width={125}>{title}</td>
      <td className="pr-2">:</td>
      <td>{value}</td>
    </tr>
  )

  return (
    <Row>
      <Col md>
        <table style={{fontSize: 14}}>
          <tbody>
            <InfoItem title="No. Dokumen" value={dataInfo.no_dokumen} />
            <InfoItem title="Tgl. Dokumen" value={dataInfo.tgl_dokumen ? DateConvert(new Date(dataInfo.tgl_dokumen)).detail : '-'} />
            <InfoItem title="No. RAP" value={dataInfo.no_rap} />
          </tbody>
        </table>
      </Col>
      <Col md>
        <table style={{fontSize: 14}}>
          <tbody>
            <InfoItem title="No. Job Order" value={dataInfo.no_job_order} />
            <InfoItem title="Customer" value={dataInfo.customer} />
            <InfoItem title="Barang Jadi" value={dataInfo.barang_jadi} />
          </tbody>
        </table>
      </Col>
    </Row>
  )
}

export default InfoSection