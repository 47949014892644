// React
import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"

// Component
import { Row, Col } from "react-bootstrap"
import {
  CRUDLayout, Alert, DataStatus, InputSearch, 
  Pagination, Table, Th, Td, ThFixed, TdFixed,
  ActionButton, BackButton, FilterButton
} from "components"
import { DateConvert, TableNumber } from "utilities"

// API
import { PenerimaanSuratJalanApi } from "api"

// Icon
import { IoAddOutline } from 'react-icons/io5'
import { ModalFilter } from "./Section"

const ListSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [data, setData] = useState([])
  const [showModalFilter, setShowModalFilter] = useState(false)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.sj_list?.filter?.active,
      tgl_surat_jalan_mulai: location?.state?.sj_list?.filter?.tgl_surat_jalan_mulai,
      tgl_surat_jalan_selesai: location?.state?.sj_list?.filter?.tgl_surat_jalan_selesai,
      tgl_delivery_order_mulai: location?.state?.sj_list?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: location?.state?.sj_list?.filter?.tgl_delivery_order_selesai,
      batas_waktu_mulai: location?.state?.sj_list?.filter?.batas_waktu_mulai,
      batas_waktu_selesai: location?.state?.sj_list?.filter?.batas_waktu_selesai,
      gudang: location?.state?.sj_list?.filter?.gudang,
      pengirim: location?.state?.sj_list?.filter?.pengirim,
      armada: location?.state?.sj_list?.filter?.armada,
    },
    pagination: {
      page: location?.state?.sj_list?.filter?.page ?? "1",
      dataLength: location?.state?.sj_list?.filter?.dataLength ?? "10",
      totalPage: location?.state?.sj_list?.filter?.totalPage ?? "1",
      dataCount: location?.state?.sj_list?.filter?.dataCount ?? "0",
    }
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    PenerimaanSuratJalanApi.list_sj({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_surat_jalan_mulai: dataFilter?.filter?.tgl_surat_jalan_mulai,
      tgl_surat_jalan_selesai: dataFilter?.filter?.tgl_surat_jalan_selesai,
      tgl_delivery_order_mulai: dataFilter?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: dataFilter?.filter?.tgl_delivery_order_selesai,
      batas_waktu_mulai: dataFilter?.filter?.batas_waktu_mulai,
      batas_waktu_selesai: dataFilter?.filter?.batas_waktu_selesai,
      gudang: dataFilter?.filter?.gudang,
      pengirim: dataFilter?.filter?.pengirim,
      armada: dataFilter?.filter?.armada,
    })
      .then(data => {
        setData(data?.data?.data ?? [])
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: data?.data?.data_count,
            totalPage: data?.data?.total_page,
          }
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    });
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => {
    setNavbarTitle("Penerimaan Surat Jalan")
    getInitialData()

    return () => {
      setIsPageLoading(false)
    }

    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    searchConfig.key,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_surat_jalan_mulai,
    dataFilter?.filter?.tgl_surat_jalan_selesai,
    dataFilter?.filter?.tgl_delivery_order_mulai,
    dataFilter?.filter?.tgl_delivery_order_selesai,
    dataFilter?.filter?.batas_waktu_mulai,
    dataFilter?.filter?.batas_waktu_selesai,
    dataFilter?.filter?.gudang,
    dataFilter?.filter?.pengirim,
    dataFilter?.filter?.armada,
  ])

  const PageContent = () => {
    const DataTable = () => {
      return (
        <>
          <b>List Data Surat Jalan Yang Siap Dibuatkan Penerimaan Surat Jalan</b>
          <Table>
            <thead>
              <tr>
                <ThFixed>No</ThFixed>
                <ThFixed>Informasi Surat Jalan</ThFixed>
                <ThFixed>Informasi Delivery Order</ThFixed>
                <Th>Gudang</Th>
                <Th>Petugas Pengiriman</Th>
                <Th>Armada Pengiriman</Th>
                <ThFixed>Batas Waktu Pengiriman</ThFixed>
                <ThFixed>Aksi</ThFixed>
              </tr>
            </thead>
            <tbody>
              {data.map((val, index) => (
                <tr key={index}>
                  <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="text-left">{val.tgl_surat_jalan ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY: "-"}</div>
                    <div className="text-left">{val.no_surat_jalan ?? "-"}</div>
                  </TdFixed>
                  <TdFixed>
                    <div className="text-left">{val.tgl_delivery_order ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY: "-"}</div>
                    <div className="text-left">{val.no_delivery_order ?? "-"}</div>
                  </TdFixed>
                  <Td>{val.nama_gudang ?? "-"}</Td>
                  <Td>{val.nama_sopir ?? "-"}</Td>
                  <Td>{val.nama_item_aset ?? "-"}</Td>
                  <TdFixed>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY: "-"}</TdFixed>
                  <TdFixed className="align-middle">
                    <ActionButton
                      text={<IoAddOutline />}
                      variant="success"
                      size='sm'
                      onClick={() => history.push('/inventory/transaksi/penerimaan-surat-jalan/tambah/'+ val.id_surat_jalan, { ...location?.state, sj_list: dataFilter})}
                    />
                  </TdFixed>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )
    }

    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={dataFilter?.pagination?.dataLength}
            dataNumber={
              dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
            }
            dataPage={
              dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
                ? dataFilter?.pagination?.dataCount
                : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
            }
            dataCount={dataFilter?.pagination?.dataCount}
            currentPage={dataFilter?.pagination?.page}
            totalPage={dataFilter?.pagination?.totalPage}
            onPaginationChange={({ selected }) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: selected + 1,
                }
              })
            }
            onDataLengthChange={(e) =>
              setDataFilter({
                ...dataFilter,
                pagination: {
                  ...dataFilter.pagination,
                  page: 1,
                  dataLength: e.target.value,
                }
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push("/inventory/transaksi/penerimaan-surat-jalan", { ...location?.state, sj_list: dataFilter })} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
      {/* Modal Filter */}
      { showModalFilter && <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      }
    </CRUDLayout>
  )
}

export default ListSuratJalan
