import React, { Component } from "react";
import { THead, TBody, Tr, TdFixed, Th, Table } from "../../../../components";
import { PenerimaanReturApi } from "../../../../api";
import { DateConvert } from "../../../../utilities";
export class ShowData extends Component {
  render() {
    return (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 200 }}>
          <span style={{ textTransform: "capitalize" }}>{this.props.title}</span>
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {this.props.text ? this.props.text : "-"}
        </div>
      </div>
    );
  }
}

export class PrintPenerimaanRetur extends Component {
  render() {
    const data = this.props.printData;
    return (
      <div style={{ marginTop: 100 }} className="px-4 mx-2">
        <div className="row">
          <div className="col-6">
            <ShowData
              title="Tgl. Penerimaan Retur"
              text={DateConvert(new Date(data.tgl_penerimaan_retur)).detail}
            />
          </div>
          <div className="col">
            <ShowData title="No. Penerimaan Retur" text={data.no_penerimaan_retur} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ShowData
              title="Tgl. Retur Penjualan"
              text={DateConvert(new Date(data.tgl_retur_penjualan)).detail}
            />
          </div>
          <div className="col">
            <ShowData title="No. pembatalan faktur" text={data.no_retur_penjualan} />
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <ShowData
              title="Tgl. Delivery Order"
              text={DateConvert(new Date(data.tgl_delivery_order)).detail}
            />
          </div>
          <div className="col">
            <ShowData title="No. Delivery Order" text={data.no_delivery_order} />
          </div>
        </div>
        <div className="my-3">
          <div className="col pl-0">
            <ShowData title="Customer" text={data.nama_customer} />
          </div>
          <div className="col pl-0">
            <ShowData title="Sales" text={data.nama_sales} />
          </div>
        </div>
        <div className="mt-4">List Barang</div>
        <Table>
          <THead>
            <Th>No.</Th>
            <Th>Kode Barang</Th>
            <Th>Nama Barang</Th>
            <Th>Gudang</Th>
            <Th>Qty SO</Th>
            <Th>Satuan</Th>
          </THead>
          <TBody>
            {data?.detail?.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{index + 1}</TdFixed>
                <TdFixed>{val.kode_item}</TdFixed>
                <TdFixed>{val.nama_item}</TdFixed>
                <TdFixed>{val.nama_gudang}</TdFixed>
                <TdFixed>{val.qty}</TdFixed>
                <TdFixed>{val.nama_satuan}</TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col-6">
            <label>Catatan Retur :</label>
            <p>{data.catatan_delivery_order}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrintPenerimaanRetur;
