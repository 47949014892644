// React
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

// API
import Axios from "axios";
import { DeliveryOrderApi } from "../../../../api";

// Component
import {
  CRUDLayout,
  InputSearch,
  CreateButton,
  UpdateButton,
  ReadButton,
  DataStatus,
  Alert,
  Pagination,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ApprovalButton,
  FilterButton,
} from "../../../../components";
import { DateConvert, TableNumber } from "../../../../utilities";
import { ModalFilter } from "./Section"

const DeliveryOrder = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  // menampung value dari search form
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const history = useHistory();
  const location = useLocation();
  let createAlert = location?.state?.variant;

  // data
  const [data, setData] = useState([]);
  console.log(data)
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: location?.state?.do?.filter?.active,
      tgl_delivery_order_mulai: location?.state?.do?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: location?.state?.do?.filter?.tgl_delivery_order_selesai,
      tgl_sales_order_mulai: location?.state?.do?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: location?.state?.do?.filter?.tgl_sales_order_selesai,
      tgl_batas_waktu_mulai: location?.state?.do?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: location?.state?.do?.filter?.tgl_batas_waktu_selesai,
      customer: location?.state?.do?.filter?.customer,
      gudang: location?.state?.do?.filter?.gudang,
      status_approval: location?.state?.do?.filter?.status_approval
    },
    pagination: {
      page: location?.state?.do?.filter?.page ?? "1",
      dataLength: location?.state?.do?.filter?.dataLength ?? "10",
      totalPage: location?.state?.do?.filter?.totalPage ?? "1",
      dataCount: location?.state?.do?.filter?.dataCount ?? "0",
    }
  })
  const [showModalFilter, setShowModalFilter] = useState(false)

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // request data ke server
    Axios.all([DeliveryOrderApi.getPage({
      q: searchKey,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      tgl_delivery_order_mulai: dataFilter?.filter?.tgl_delivery_order_mulai,
      tgl_delivery_order_selesai: dataFilter?.filter?.tgl_delivery_order_selesai,
      tgl_sales_order_mulai: dataFilter?.filter?.tgl_sales_order_mulai,
      tgl_sales_order_selesai: dataFilter?.filter?.tgl_sales_order_selesai,
      tgl_batas_waktu_mulai: dataFilter?.filter?.tgl_batas_waktu_mulai,
      tgl_batas_waktu_selesai: dataFilter?.filter?.tgl_batas_waktu_selesai,
      customer: dataFilter?.filter?.customer,
      gudang: dataFilter?.filter?.gudang,
      status_approval: dataFilter?.filter?.status_approval
    })])
      .then(
        Axios.spread((res) => {
          setData(res?.data?.data ?? []);
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              dataCount: res?.data?.data_count,
              totalPage: res?.data?.total_page,
            }
          });
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey !== "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        } else {
          setShowAlert(false);
        }
        setIsLoading(false);
      });
  };

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase();
    if (newStatus === "REJ") {
      return {
        variant: "outline-danger",
        label: "REJECTED"
      }
    }
    if (newStatus === "REV") {
      return {
        variant: "outline-warning",
        label: "REVISION"
      }
    }
    if (newStatus === "APP") {
      return {
        variant: "outline-success",
        label: "APPROVED"
      }
    }
    if (newStatus === "VER") {
      return {
        variant: "outline-success",
        label: "VERIFIED"
      }
    }
    return {
      variant: "outline-secondary",
      label: "PENDING"
    }
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Delivery Order");
    if (createAlert !== undefined) {
      setShowAlert(true);
      setAlertConfig({ variant: location?.state?.variant, text: location?.state?.text });

      history.replace((createAlert = undefined));
    } else {
      return getData();
    }
    getData();
    return () => {
      setIsLoading(false);
      setIsSearching(false);
      setShowAlert(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setNavbarTitle, 
    searchKey,
    dataFilter?.pagination?.page,
    dataFilter?.pagination?.dataLength,
    dataFilter?.filter?.active,
    dataFilter?.filter?.tgl_delivery_order_mulai,
    dataFilter?.filter?.tgl_delivery_order_selesai,
    dataFilter?.filter?.tgl_sales_order_mulai,
    dataFilter?.filter?.tgl_sales_order_selesai,
    dataFilter?.filter?.tgl_batas_waktu_mulai,
    dataFilter?.filter?.tgl_batas_waktu_selesai,
    dataFilter?.filter?.customer,
    dataFilter?.filter?.gudang,
    dataFilter?.filter?.status_approval 
  ]);

  const Table = () => {
    return (
      <>
        <span style={{fontSize:'14px'}}><b>List Data Delivery Order</b></span>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi Delivery Order</ThFixed>
              <ThFixed>Informasi Sales Order</ThFixed>
              <Th width={300}>Customer</Th>
              <Th width={200}>Gudang</Th>
              <Th width={350}>Item Barang</Th>
              <Th width={100}>Tgl. Batas Waktu Pengiriman</Th>
              <ThFixed>Status Approval</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
                <TdFixed>
                  <div className="d-flex justify-content-center">
                    <ReadButton
                      onClick={() => {
                        history.push(
                          `/inventory/transaksi/delivery-order/detail-delivery-order/${val.id_delivery_order}`, { ...location?.state, do: dataFilter }
                        );
                      }}
                    />
                    {(val.status_approval === "REV" || !val.status_approval) && (
                      <UpdateButton
                        onClick={() =>
                          history.push(
                            `/inventory/transaksi/delivery-order/edit-delivery-order/${val.id_delivery_order}`,
                            {
                              data: {
                                catatan: val.catatan,
                                alamat_pengiriman: val.alamat_pengiriman,
                              },
                              ...location?.state, do: dataFilter
                            }
                          )
                        }
                      />
                    )}
                  </div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_delivery_order ? DateConvert(new Date(val.tgl_delivery_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_delivery_order ?? "-"}</div>
                </TdFixed>
                <TdFixed>
                  <div className="text-left">{val.tgl_sales_order ? DateConvert(new Date(val.tgl_sales_order)).defaultDMY : "-"}</div>
                  <div className="text-left">{val.no_sales_order ?? "-"}</div>
                </TdFixed>
                <Td>{val.nama_customer ?? "-"}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                <Td>
                  <ul className="pl-3">
                    {val.barang_jadi
                      ? val.barang_jadi.map((item, index) => (
                          <li key={index}>{item.nama_barang}</li>
                        ))
                      : ""}
                  </ul>
                </Td>
                <TdFixed>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY : "-"}</TdFixed>
                <TdFixed>
                  <ApprovalButton status={val.status_approval} />
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
        <Pagination
          dataLength={dataFilter?.pagination?.dataLength}
          dataNumber={
            dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
          }
          dataPage={
            dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
              ? dataFilter?.pagination?.dataCount
              : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
          }
          dataCount={dataFilter?.pagination?.dataCount}
          currentPage={dataFilter?.pagination?.page}
          totalPage={dataFilter?.pagination?.totalPage}
          onPaginationChange={({ selected }) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: selected + 1,
              }
            })
          }
          onDataLengthChange={(e) =>
            setDataFilter({
              ...dataFilter,
              pagination: {
                ...dataFilter.pagination,
                page: 1,
                dataLength: e.target.value,
              }
            })
          }
        />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setDataFilter({
                    ...dataFilter,
                    pagination: {
                      ...dataFilter.pagination,
                      page: "1",
                      dataLength: "10",
                      totalPage: "1",
                      dataCount: "0",
                    }
                  });
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
            <FilterButton 
              active={dataFilter?.filter?.active}
              onClick={() => setShowModalFilter(true)}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          {/* <ExportButton /> */}
          <CreateButton
            onClick={() => {
              history.push("/inventory/transaksi/delivery-order/list-so", { ...location?.state, do: dataFilter});
            }}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>
      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />
      {isLoading === true ? (
        <DataStatus loading={true} text="Memuat data..." />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}


      { showModalFilter && <ModalFilter
          show={showModalFilter}
          setShow={setShowModalFilter}
          data={dataFilter}
          setData={setDataFilter}
        />
      }
    </CRUDLayout>
  );
};

export default DeliveryOrder;
