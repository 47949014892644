import React, { useState, useEffect } from "react";
import {
  Modal
} from "react-bootstrap";
import {
  Formik
} from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  CRUDLayout,
  Input,
  InputSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Select,
  Alert,
  Pagination,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ListData,
  ReadButton,
  ReadModal,
  Switch,
} from "components";
import {
  PageNumber,
  TableNumber,
  HeadOfficeStatus
} from "utilities";
import {
  JenisApi
} from "api";

const Jenis = ({ setNavbarTitle }) => {
  // Title
  const title = "Jenis Barang";

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE
  const [isReadForm, setIsReadForm] = useState(false); // MODAL HAPUS STATE

  // STATE DATA JENIS
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState("");
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);
  // STATE MENAMPUNG DATA KELOMPOK
  const [dataKelompok, setDataKelompok] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  });

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);
    setShowAlert(false);

    Axios.all([JenisApi.getPage(page, dataLength, searchKey), JenisApi.getDataKelompok()])
      .then(
        Axios.spread((res, dataKelompok) => {
          setData(res.data.data);
          setDataKelompok(dataKelompok.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch((err) => alert(err))
      .finally(() => {
        if (searchKey != "") {
          setAlertConfig({
            variant: "primary",
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // FUNCTION SWITCH HIDDEN DATA
  const changeDataStatus = (status, id) => {
    setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_jenis: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "primary",
        text: "Ubah status data berhasil",
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: "danger",
        text: "Ubah status data gagal",
      });
      setShowAlert(true);
    };

    status === true
      ? JenisApi.show(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData())
      : JenisApi.hide(value)
        .then(() => onLoadedSuccess())
        .catch(() => onLoadedFailed())
        .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title);
    getData();

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  const checkDuplicate = (value) => {
    // cek duplicate ketika update data
    if (isUpdateForm) {
      if (updateData.nama_jenis !== value) {
        return JenisApi.checkDuplicateNama({ nama_jenis: value })
          .then(() => true)
          .catch(() => false)
      }
      return true
    }
    else {
      // cek duplicate ketika tambah data
      return JenisApi.checkDuplicateNama({ nama_jenis: value })
        .then(() => true)
        .catch(() => false)
    }
  }

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    id_kelompok: Yup.string().required("Pilih kelompok"),
    nama_jenis: Yup.string()
      .required("Masukkan nama jenis barang")
      .test("checkDuplicate", "Nama jenis sudah digunakan", async (value) => await checkDuplicate(value)),
    // keterangan_jenis: Yup.string().required("Masukkan keterangan"),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    const formInitialValues = {
      id_kelompok: "",
      nama_jenis: "",
      keterangan_jenis: "",
    };

    const formSubmitHandler = (values) => {
      JenisApi.create(values)
        .then((res) => setAlertConfig({
          variant: "primary",
          text: "Tambah data berhasil!",
        }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          text: `Tambah data gagal! (${err.response.data.message})`,
        }))
        .finally(() => {
          setIsCreateForm(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <CreateModal show={isCreateForm} onHide={() => setIsCreateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="Kelompok Barang"
                  name="id_kelompok"
                  onChange={handleChange}
                  error={errors.id_kelompok && touched.id_kelompok && true}
                  errorText={errors.id_kelompok}
                  defaultValue=""
                >
                  <option value="">Pilih Kelompok Barang</option>
                  {dataKelompok.map((e) => (
                    <option className="text-uppercase" key={e.id_kelompok} value={e.id_kelompok}>
                      {e.nama_kelompok}
                    </option>
                  ))}
                </Select>

                <Input
                  label="Nama Jenis Barang"
                  type="text"
                  name="nama_jenis"
                  placeholder="Nama Jenis"
                  value={values.nama_jenis}
                  onChange={handleChange}
                  error={Boolean(errors.nama_jenis) && true}
                  errorText={Boolean(errors.nama_jenis) && errors.nama_jenis}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan_jenis"
                  placeholder="Keterangan"
                  value={values.keterangan_jenis}
                  onChange={handleChange}
                  error={errors.keterangan_jenis && touched.keterangan_jenis && true}
                  errorText={errors.keterangan_jenis}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Tambah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_kelompok: updateData.id_kelompok,
      nama_jenis: updateData.nama_jenis,
      keterangan_jenis: updateData.keterangan_jenis,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_jenis: updateData.id_jenis,
        ...values,
      };
      JenisApi.update(finalValues)
        .then((res) => setAlertConfig({
          variant: "primary",
          text: "Ubah data berhasil!",
        }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          text: `Ubah data gagal! (${err.response.data.message})`,
        }))
        .finally(() => {
          setIsUpdateForm(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <UpdateModal show={isUpdateForm} onHide={() => setIsUpdateForm(false)} title={title}>
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="Kelompok Barang"
                  name="id_kelompok"
                  onChange={handleChange}
                  error={errors.id_kelompok && touched.id_kelompok && true}
                  errorText={errors.id_kelompok}
                  defaultValue={updateData.id_kelompok}
                >
                  <option value="">Pilih Kelompok Barang</option>
                  {dataKelompok.map((e) => (
                    <option className="text-uppercase" key={e.id_kelompok} value={e.id_kelompok}>
                      {e.nama_kelompok}
                    </option>
                  ))}
                </Select>

                <Input
                  label="Nama Jenis Barang"
                  type="text"
                  name="nama_jenis"
                  placeholder="Nama Jenis"
                  value={values.nama_jenis}
                  onChange={handleChange}
                  error={Boolean(errors.nama_jenis) && true}
                  errorText={Boolean(errors.nama_jenis) && errors.nama_jenis}
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan_jenis"
                  placeholder="Keterangan"
                  value={values.keterangan_jenis}
                  onChange={handleChange}
                  error={errors.keterangan_jenis && touched.keterangan_jenis && true}
                  errorText={errors.keterangan_jenis}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex justify-content-end">
                  <ActionButton
                    type="submit"
                    variant="success"
                    text="Ubah"
                    className="mt-2 px-4"
                    loading={isSubmitting}
                  />
                </div>
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL DETAIL COMPONENT
  const DetailModal = () => (
    <ReadModal show={isReadForm} onHide={() => setIsReadForm(false)} title={title}>
      <Modal.Body>
        <ListData label="Kelompok Barang">{updateData.nama_kelompok}</ListData>
        <ListData label="Nama Jenis Barang">{updateData.nama_jenis}</ListData>
        <ListData label="Keterangan">{updateData.keterangan}</ListData>
      </Modal.Body>
    </ReadModal>
  );

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_jenis: deleteData.id_jenis };

    const [btnLoading, setBtnLoading] = useState(false);

    const deleteDataHandler = () => {
      setBtnLoading(true);
      JenisApi.delete(deleteValue)
        .then((res) => setAlertConfig({
          variant: "primary",
          text: "Hapus data berhasil!",
        }))
        .catch((err) => setAlertConfig({
          variant: "danger",
          text: `Hapus data gagal! (${err.response.data.message})`,
        }))
        .finally(() => {
          setIsDeleteData(false);
          setShowAlert(true);
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Nama Jenis : {deleteData.nama_jenis}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Kelompok Barang</Th>
              <Th>Nama Jenis Barang</Th>
              <Th>Keterangan</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_jenis}>
                  <TdFixed>{PageNumber(page, dataLength, index)}</TdFixed>
                  <TdFixed>
                    <div className="d-flex justify-content-center">
                      <ReadButton
                        onClick={() => {
                          setUpdateData(val);
                          setIsReadForm(true);
                        }}
                      />
                      {HeadOfficeStatus() && (
                        <>
                          <UpdateButton
                            onClick={() => {
                              setUpdateData(val);
                              setIsUpdateForm(true);
                            }}
                          />
                          <DeleteButton
                            onClick={() => {
                              setDeleteData(val);
                              setIsDeleteData(true);
                            }}
                          />
                          <Switch
                            id={val.id_jenis}
                            checked={val.is_hidden === false ? true : false}
                            onChange={() => changeDataStatus(val.is_hidden, val.id_jenis)}
                          />
                        </>
                      )}
                    </div>
                  </TdFixed>
                  <Td>{val.nama_kelompok}</Td>
                  <Td>{val.nama_jenis}</Td>
                  <Td>{val.keterangan_jenis}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>

        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                  if (index == data.length - 1) {
                    return TableNumber(page, dataLength, index);
                  }
                })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          {/* <ExportButton /> */}
          {/* Button Tambah */}
          {HeadOfficeStatus() && <CreateButton onClick={() => setIsCreateForm(true)} />}
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      < Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {
        isLoading ? (
          <DataStatus loading={isLoading} text="Memuat Data" />
        ) : !Array.isArray(data) ? (
          <DataStatus text="Tidak dapat memuat data" />
        ) : data.length > 0 ? (
          <Table />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      }

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
      <DetailModal />
    </CRUDLayout >
  );
};

export default Jenis;
