import {
  useState,
  useEffect
} from "react"
import {
  useHistory,
  useParams,
  useLocation
} from "react-router-dom"
import {
  Card,
  Modal
} from "react-bootstrap"
import {
  Formik
} from "formik"
import * as Yup from "yup"
import Axios from 'axios'
import {
  Alert,
  BackButton,
  DataStatus,
  ActionButton
} from "components"
import {
  DateConvert
} from "utilities"
import {
  TransferBahanJobMixApi
} from 'api'
import {
  InfoSection,
  FormSection,
  TableSection
} from './Section'

const TambahTransferBahanJobMix = ({ setNavbarTitle }) => {
  const today = DateConvert(new Date()).default
  const history = useHistory()
  const location = useLocation()
  const { id_item_buaso } = location.state
  const { id_jobmix_permintaan } = useParams()
  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [dropdownGudang, setDropdownGudang] = useState([])
  const [dropdownBahan, setDropdownBahan] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const mappingDataInfo = data => Object({
    ...data,
    nama_item_bahan: `${data?.kode_item ? `(${data.kode_item}) ` : ''}${data.nama_item ?? '-'}`,
    qty_permintaan_jobmix: `${data?.qty_permintaan?.replace(".", ",") ?? 0} ${data?.nama_satuan ?? ""}`
  })

  const mappingDropdownGudang = data => data.map(val => Object({
    ...val,
    label: val.nama_gudang,
    value: val.id_gudang,
  }))

  const mappingDropdownBahan = data => data.map(val => Object({
    ...val,
    label: `${val?.kode_item ? `(${val.kode_item}) ` : ''}${val.nama_item ?? '-'}`,
    value: val.id_item_buaso
  }))

  const mappingDataTable = data => data.map(val => Object({
    ...val,
    item_bahan: `${val?.kode_item ? `(${val.kode_item}) ` : ''}${val.nama_item ?? '-'}`,
    qty_transfer_bahan: `${val?.qty_transfer?.replace(".", ",") ?? 0} ${val?.kode_satuan ?? ""}`
  }))

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    Axios.all([
      TransferBahanJobMixApi.getSinglePermintaan({ id_jobmix_permintaan }),
      TransferBahanJobMixApi.getDropdownGudang({ id_item_buaso }),
      TransferBahanJobMixApi.getDropdownBahan()
    ])
      .then(Axios.spread((data, gudang, bahan) => {
        const getData = data.data.data
        const getGudang = gudang.data.data
        const getBahan = bahan.data.data

        const mapDataInfo = mappingDataInfo(getData ?? {})
        const mapDataTable = mappingDataTable(getData.history ?? [])
        const mapDropdownGudang = mappingDropdownGudang(getGudang ?? [])
        const mapDropdownBahan = mappingDropdownBahan(getBahan ?? [])

        setDataInfo(mapDataInfo)
        setDataTable(mapDataTable)
        setDropdownGudang(mapDropdownGudang)
        setDropdownBahan(mapDropdownBahan)

        setFetchingStatus({
          loading: false,
          success: true
        })
      }))
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  const formInitialValues = {
    tanggal: today,
    nomor: "",
    id_item_buaso: dataInfo.id_item_permintaan === '421' ? "" : dataInfo.id_item_permintaan,
    id_satuan_transfer: dataInfo.id_item_permintaan === '421' ? "" : dataInfo.id_satuan,
    id_item_permintaan: dataInfo.id_item_permintaan ?? "",
    id_satuan_permintaan: dataInfo.id_satuan ?? "",
    nama_kelompok: dataInfo?.nama_kelompok ?? "",
    nama_jenis: dataInfo?.nama_jenis ?? "",
    satuan: dataInfo.nama_satuan ?? "",
    id_pabrik: dataInfo.id_pabrik ?? "",
    id_gudang_asal: "",
    id_gudang_tujuan: "",
    qty_stok: "",
    qty_transfer: "",
  }

  const formValidationSchema = Yup.object().shape({
    tanggal: Yup.string().required("Pilih tanggal"),
    nomor: Yup.string().required("Pilih tanggal untuk menentukan nomor"),
    qty_transfer: Yup.number().required("Masukkan Qty. Transfer").min(1, "Qty. Transfer minimal 1"),
    id_gudang_asal: Yup.string().required("Pilih Gudang Asal"),
    id_gudang_tujuan: Yup.string().required("Pilih Gudang Tujuan"),
    id_item_buaso: Yup.string().required("Pilih Item Bahan"),
  })

  const formSubmitHandler = (values, { setSubmitting }) => {
    const finalValues = {
      id_jobmix_permintaan,
      id_pabrik: values.id_pabrik,
      id_item_permintaan: values.id_item_permintaan,
      id_satuan_permintaan: values.id_satuan_permintaan,
      tgl_jobmix_transfer_bahan: values.tanggal,
      no_jobmix_transfer_bahan: values.nomor,
      id_item_buaso: values.id_item_buaso,
      id_satuan_transfer: values.id_satuan_transfer,
      qty_transfer: values.qty_transfer,
      id_gudang_asal: values.id_gudang_asal,
      id_gudang_tujuan: values.id_gudang_tujuan,
    }

    TransferBahanJobMixApi.save(finalValues)
      .then((res) => {
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Data berhasil disimpan!"
        })
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal disimpan!"
        })
      })
      .finally(() => {
        setSubmitting(false)
        getInitialData()
      })
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle("TAMBAH DATA TRANSFER BAHAN JOB MIX")
  }, [])

  return (
    <Card style={{ minHeight: "80vh" }}>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Detail Permintaan Job Mix</b>
        <BackButton
          size="sm"
          onClick={() => history.goBack()}
        />
      </Card.Header>
      <Card.Body className="pb-5">
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() => setAlertConfig({
            ...alertConfig,
            show: false
          })}
        />
        {
          fetchingStatus.loading ? <DataStatus loading text="Memuat . . ." />
            : fetchingStatus.success ?
              <Formik
                initialValues={formInitialValues}
                validationSchema={formValidationSchema}
                onSubmit={formSubmitHandler}
              >
                {formik => (
                  <form onSubmit={formik.handleSubmit}>
                    <InfoSection
                      dataInfo={dataInfo}
                    />
                    <hr className="mb-1" />
                    <FormSection
                      formik={formik}
                      dataInfo={dataInfo}
                      dropdownGudang={dropdownGudang}
                      dropdownBahan={dropdownBahan}
                    />
                    <hr className="mt-1" />
                    <TableSection
                      formik={formik}
                      dataTable={dataTable}
                      setDataTable={setDataTable}
                    />
                    <hr />
                    <div className="d-flex justify-content-end">
                      <ActionButton
                        type="submit"
                        text="Simpan"
                        loading={formik.isSubmitting}
                      />
                    </div>
                  </form>
                )}
              </Formik>
              : <DataStatus loading text="Data gagal dimuat!" />
        }
      </Card.Body>
    </Card>
  )
}

export default TambahTransferBahanJobMix
