import { useState, useEffect } from "react";
import { Modal, Row, Col, Card } from "react-bootstrap";
import {
  CRUDLayout,
  ActionButton,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  DataStatus,
  ReadModal
} from "components";
import { DateConvert } from "utilities";
import { PurchaseRequestApi } from "api";

const ModalDetail = ({ modalConfig, setModalConfig, setAlertConfig, handlePrint }) => {
  const { id } = modalConfig
  const [readData, setReadData] = useState({ detail: [] });
  const [isLoadingRead, setIsLoadingRead] = useState(false);

  useEffect(() => {
    setIsLoadingRead(true);
    getReadData(id);

  }, []);

  const getReadData = (id) => {
    setReadData({ detail: [] });

    PurchaseRequestApi.single(id)
      .then((res) => {
        setReadData(res.data.data);
        setIsLoadingRead(false);
      })
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Purchase Request gagal dimuat",
        });
      });
  };

  const FormCard = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval Purchase Request</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {readData.stakeholder === undefined
              ? "Memuat Data"
              : readData?.stakeholder?.map(
                (val, index) =>
                  index !== 0 &&
                  val.status_approval !== "PEN" && (
                    <Col sm>
                      <InfoItem
                        title1={
                          val.status_approval === "VER" || val.status_approval === "REV"
                            ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""
                            }`
                            : val.status_approval === "APP"
                              ? "Pengesah"
                              : "Di Tolak Oleh"
                        }
                        value1={val.nama_karyawan ?? "-"}
                        title2="Catatan"
                        value2={val.catatan ?? "-"}
                      />
                    </Col>
                  )
              )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  const ShowData = ({ title, text, line }) => (
    <div>
      <small className="text-secondary" style={{ opacity: 0.8, textTransform: "capitalize" }}>
        {title}
      </small>
      <div className="text-dark">
        <small>{text ? text : "-"}</small>
      </div>
      {line && <hr className="my-2" />}
    </div>
  );

  return (
    <>
      <ReadModal
        size="xl"
        title="Purchase Request"
        show={modalConfig.show}
        onHide={() => setModalConfig({ show: false, type: 'detail', id: null })}
      >
        <Modal.Body>
          {isLoadingRead == true ? (
            <DataStatus loading={true} text="Memuat data..." />
          ) : (
            <>
              <div className="mb-2">
                <Row>
                  <Col lg="6">
                    <ShowData
                      line
                      title="Tgl. Purchase Request"
                      text={DateConvert(new Date(readData.tgl_purchase_request)).detail}
                    />
                  </Col>
                  <Col lg="6">
                    <ShowData
                      line
                      title="No. Purchase Request" text={readData.no_purchase_request} />
                  </Col>
                </Row>

                <Row className="mb-3">
                  <Col lg="6">
                    <ShowData
                      line
                      title="Tgl. Pemakaian"
                      text={DateConvert(new Date(readData.tgl_pemakaian)).detail}
                    />
                  </Col>
                  <Col lg="6">
                    <ShowData
                      line
                      title="Keperluan" text={readData.keperluan} />
                  </Col>
                </Row>

                <CRUDLayout.Table>
                  <THead>
                    <Tr className="text-center">
                      <ThFixed>No</ThFixed>
                      <Th>Kode Item</Th>
                      <Th>Nama Item</Th>
                      <Th>Satuan</Th>
                      <Th>Qty</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {readData.detail.map((val, index) => (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>{val.nama_item}</Td>
                        <Td>{val.nama_satuan}</Td>
                        <Td>
                          <div className="text-right">{val.qty}</div>
                        </Td>
                      </Tr>
                    ))}
                  </TBody>
                </CRUDLayout.Table>

                <div>
                  <strong>Catatan : </strong>
                  <br></br>
                  {readData.catatan_purchase_request}
                </div>
              </div>
              <FormCard />
            </>
          )}
        </Modal.Body>

        {readData.status_approval == "app" ? (
          <Modal.Footer>
            <ActionButton
              type="button"
              variant="primary"
              text="Cetak Purchase Request"
              onClick={handlePrint}
            />
          </Modal.Footer>
        ) : (
          <></>
        )}
      </ReadModal>
    </>
  );
};

export default ModalDetail