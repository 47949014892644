import { Button } from 'react-bootstrap'

const VARIANT = {
  "PEN": ['outline-secondary', 'PENDING'],
  "VER": ['outline-success', 'VERIFIED'],
  "APP": ['outline-success', 'APPROVED'],
  "REV": ['outline-warning', 'REVISI'],
  "REJ": ['outline-danger', 'REJECT'],
}

const ApprovalStatusButton = ({ status, style, ...rest }) => (
  <Button
    block
    size="sm"
    variant={VARIANT[status ?? "PEN"][0]}
    style={{
      pointerEvents: 'none',
      borderWidth: '2px',
      ...style
    }}
    {...rest}
  >
    <b>{VARIANT[status ?? "PEN"][1]}</b>
  </Button>
)

export default ApprovalStatusButton