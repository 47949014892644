import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Row, Col } from "react-bootstrap"
import { IoEyeOutline } from "react-icons/io5"
import _ from "lodash"
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  UpdateButton,
  ReadButton,
  InputSearch,
  Pagination,
  Table,
  Th,
  Td,
  ThFixed,
  TdFixed,
  CreateButton,
} from "components"
import { DateConvert, TableNumber } from "utilities"
import { SuratJalanApi } from "api"

const ListSuratJalan = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [dataSuratJalan, setDataSuratJalan] = useState([])
  const [paginationConfig, setPaginationConfig] = useState({
    page: "1",
    dataLength: "10",
    totalPage: "1",
    dataCount: "0",
  })
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: "",
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  })

  const getInitialData = () => {
    setIsPageLoading(true)

    SuratJalanApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataSuratJalan(data.data)
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        })
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        })
      })
      .finally(() => setIsPageLoading(false))
  }

  const generateStatusApproval = (status) => {
    const newStatus = status?.toUpperCase()
    if (newStatus === "REJ") {
      return "REJECTED"
    }

    if (newStatus === "REV") {
      return "REVISI"
    }

    if (newStatus === "APP") {
      return "APPROVED"
    }

    if (newStatus === "VER") {
      return "VERIFIED"
    }

    return "PENDING"
  }

  const checkAlert = () => {
    const locationState = location.state

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        })
      }
    }
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setPaginationConfig({
      page: "1",
      dataLength: "10",
      totalPage: "1",
      dataCount: "0",
    })
    setAlertConfig({
      show: key ? true : false,
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
  }

  useEffect(() => {
    setNavbarTitle("List Surat Jalan")
    getInitialData()
    checkAlert()

    return () => {
      setIsPageLoading(false)
    }
  }, [searchConfig.key, paginationConfig.page, paginationConfig.dataLength])

  const PageContent = () => {
    const DataTable = () => (
      <>
        <b>List Data Surat Jalan</b>
        <Table>
          <thead>
            <tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Aksi</ThFixed>
              <Th>Tgl. Surat Jalan</Th>
              <Th>No. Surat Jalan</Th>
              <Th>No. Delivery Order</Th>
              <Th>Gudang</Th>
              <Th>Petugas Pengiriman</Th>
              <Th>Armada Pengiriman</Th>
              <Th>Tgl. Batas Waktu</Th>
              <Th>Status Approval</Th>
            </tr>
          </thead>
          <tbody>
            {dataSuratJalan.map((val, index) => (
              <tr key={index}>
                <Td>{TableNumber(paginationConfig.page, paginationConfig.dataLength, index)}</Td>
                <Td className="d-flex">
                  <ReadButton
                    onClick={() =>
                      history.push("/inventory/transaksi/surat-jalan/detail/" + val.id_surat_jalan)
                    }
                  />
                  {(val.status_approval === "REV" || !val.status_approval) && (
                    <UpdateButton
                      onClick={() =>
                        history.push("/inventory/transaksi/surat-jalan/ubah/" + val.id_surat_jalan)
                      }
                    />
                  )}
                </Td>
                <Td>
                  {val.tgl_surat_jalan
                    ? DateConvert(new Date(val.tgl_surat_jalan)).defaultDMY
                    : "-"}
                </Td>
                <Td>{val.no_surat_jalan ?? "-"}</Td>
                <Td>{val.no_delivery_order ?? "-"}</Td>
                <Td>{val.nama_gudang ?? "-"}</Td>
                <Td>{val.nama_sopir ?? "-"}</Td>
                <Td>{`${val.plat_nomor ? `${val.plat_nomor} - ` : ""}${val.nama_item_aset}`}</Td>
                <Td>{val.batas_waktu ? DateConvert(new Date(val.batas_waktu)).defaultDMY : "-"}</Td>
                <Td>{generateStatusApproval(val.status_approval)}</Td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    )

    if (!dataSuratJalan || dataSuratJalan.length < 1) {
      return <DataStatus text="Tidak ada data" />
    }

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength - paginationConfig.dataLength + 1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    )
  }

  return (
    <CRUDLayout>
      {/* Head Section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="mb-2">
            <Col md={8}>
              <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() => history.push("/inventory/transaksi/surat-jalan/delivery-order")}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* Content */}
      {isPageLoading ? <DataStatus loading={true} text="Memuat data . . ." /> : <PageContent />}
    </CRUDLayout>
  )
}

export default ListSuratJalan
