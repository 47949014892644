import Services from "../../../services";

class RegItemAlatMesinApi {
  getPage(page, dataLength, key) {
    return Services.get(`/alatmesin/page/?page=${page}&per_page=${dataLength}&q=${key}`);
  }
  getBuaso() {
    return Services.get("/buaso");
  }
  getSatuan() {
    return Services.get("/satuan/dropdown");
  }
  getKelompok() {
    return Services.get("/alatmesin/kelompok?id_buaso=3");
  }
  getPabrikan() {
    return Services.get("/pabrikan/dropdown");
  }
  generateKodeItem() {
    return Services.get("/alatmesin/no_baru");
  }
  create(data) {
    return Services.post("/alatmesin", data);
  }
  update(data) {
    return Services.put("/alatmesin", data);
  }
  delete(id_alatmesin) {
    return Services.post("/alatmesin/delete", id_alatmesin);
  }
  search(key) {
    return Services.get(`alatmesin/page/?q=${key}`);
  }
  show(id_alatmesin) {
    return Services.put("/alatmesin/show", id_alatmesin);
  }
  hide(id_alatmesin) {
    return Services.put("/alatmesin/hide", id_alatmesin);
  }
}

export default new RegItemAlatMesinApi();
