// React
import { useState } from "react"

// Component
import { 
	ActionButton, CRUDLayout, Input, TBody,
	Td, TdFixed, TextArea, Th, THead, ThFixed,
	Tr
} from "components"
import { Col, Row } from "react-bootstrap"

// API
import { DeliveryOrderApi } from "api"

const FormSection = ({
	data, values, errors, touched, isSubmitting,
	setFieldValue, handleChange, type
}) => {
	const [kode, setKode] = useState("")
	const [isNomorLoading, setIsNomorLoading] = useState(false)

	const getNomor = (value, setFieldValue) => {
		setIsNomorLoading(true)

		DeliveryOrderApi.getKode(value)
			.then(({ data }) => {
				setKode(data.data)
				setFieldValue("no_delivery_order", data.data)
			})
			.catch(() => alert("Gagal mengambil nomor Delivery Order"))
			.finally(() => setIsNomorLoading(false))
	}

	return (
		<>
			<Row>
				<Col lg="3">
					<Input
						label="Tanggal Delivery Order"
						type="date"
						name="tgl_delivery_order"
						value={values.tgl_delivery_order}
						onChange={(e) => {
							const value = e.target.value

							setFieldValue("tgl_delivery_order", value)
							getNomor(value, setFieldValue)
						}}
						error={errors.tgl_delivery_order && touched.tgl_delivery_order && true}
						errorText={errors.tgl_delivery_order}
					/>
				</Col>
				<Col lg="3">
					<Input
						label="No. Delivery Order"
						type="text"
						name="no_delivery_order"
						value={data.no_delivery_order}
						placeholder={
							isNomorLoading
								? "Memuat . . . "
								: values.tgl_delivery_order
									? kode
									: "Pilih tanggal terlebih dahulu"
						}
						readOnly={true}
						onChange={(e) => {
							setFieldValue("no_delivery_order", e.target.value)
						}}
						error={errors.no_delivery_order && touched.no_delivery_order && true}
						errorText={errors.no_delivery_order}
					/>
				</Col>
			</Row>

			<TableSection type={type} data={data} />

			<TextArea
				label="Catatan Delivery Order"
				type="text"
				name="catatan_delivery_order"
				value={values.catatan_delivery_order}
				onChange={handleChange}
				rows={2}
				error={errors.catatan_delivery_order && touched.catatan_delivery_order && true}
				errorText={errors.catatan_delivery_order}
			/>

			<div className="d-flex justify-content-end">
				<ActionButton
					type="submit"
					variant="primary"
					text="Simpan"
					className="mt-2 px-4"
					loading={isSubmitting}
				/>
			</div>
		</>
	)
}

const TableSection = ({ type, data }) => (
	<>
		<small>List Barang</small>
		<CRUDLayout.Table>
			<THead>
				<Tr className="text-center">
					<ThFixed>No</ThFixed>
					<ThFixed>Kode barang</ThFixed>
					<Th>Nama barang</Th>
					<ThFixed>Qty</ThFixed>
					<ThFixed>Satuan</ThFixed>
				</Tr>
			</THead>
			<TBody>
				{data?.detail?.map((val, index) => (
					<Tr key={index}>
						<TdFixed>{index + 1}</TdFixed>
						<TdFixed>{val.kode_barang}</TdFixed>
						<Td>{val.nama_barang}</Td>
						<Td>{type === 'create' ? parseInt(val.qty_item) : parseInt(val.qty)}</Td>
						<Td>{val.nama_satuan}</Td>
					</Tr>
				))}
			</TBody>
		</CRUDLayout.Table>
	</>
)

export { FormSection, TableSection }