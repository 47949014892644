import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  Input,
  BackButton,
  DataStatus,
  TextArea,
  SelectSearch,
  Select,
} from "../../../../components";
import { PenerimaanReturApi } from "../../../../api";
import { useHistory } from "react-router-dom";
import { DateConvert } from "../../../../utilities";
import { Card, Col, Row } from "react-bootstrap";
import { Formik } from "formik";

const EditPenerimaanRetur = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  // data
  const [data, setData] = useState([]);
  const [kode, setKode] = useState([]);
  const [dataGudang, setDataGudang] = useState([]);

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  });

  const { id } = useParams();

  // request data dari server
  const getData = () => {
    setIsLoading(true);
    Axios.all([
      PenerimaanReturApi.getFormEdit(id),
      PenerimaanReturApi.getKode(),
      PenerimaanReturApi.getGudang(),
    ])
      .then(
        Axios.spread((res, kode, gudang) => {
          setData(res.data.data);
          setKode(kode.data.data);
          setDataGudang(gudang.data.data);
        })
      )
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Edit Penerimaan Retur");

    // jalankan function request data ke server
    getData();
    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const TambahData = () => {
    const [isNomorLoading, setIsNomorLoading] = useState(false);
    const [kode, setKode] = useState("");

    const getNomor = (value, setFieldValue) => {
      setIsNomorLoading(true);

      PenerimaanReturApi.getKode(value)
        .then(({ data }) => {
          setKode(data.data);
          setFieldValue("no_penerimaan_retur", data.data);
        })
        .catch((err) => alert("Gagal Mengembail Nomor Penerimaan Retur"))
        .finally(() => setIsNomorLoading(false));
    };

    const formInitialValues = {
      id_retur_penjualan: id,
      tgl_penerimaan_retur: "",
    };

    const formValidationSchema = Yup.object().shape({
      tgl_penerimaan_retur: Yup.string().required("Masukan Tanggal Penerimaan Retur  "),
    });

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        ...values,
        id_retur_penjualan: id,
      };
      PenerimaanReturApi.create(finalValues)
        .then(() => {
          history.push("/inventory/transaksi/penerimaan-retur/", {
            variant: "primary",
            text: "Data berhasil di tambah",
          });
        })
        .catch((err) => {
          history.push("/inventory/transaksi/penerimaan-retur/", {
            variant: "danger",
            text: `Tambah data gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    const ShowData = ({ title, text }) => (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          <span style={{ textTransform: "capitalize" }}>{title}</span>
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {text ? text : "-"}
        </div>
      </div>
    );

    return (
      <>
        <Row>
          <Col lg="6">
            <ShowData
              title="Tgl. Delivery Order"
              text={DateConvert(new Date(data.tgl_delivery_order)).detail}
            />
          </Col>
          <Col lg="6">
            <ShowData title="No. Delivery Order" text={data.no_delivery_order} />
          </Col>
        </Row>
        <Row>
          <Col lg="6">
            <ShowData
              title="Tgl. Retur Penjualan"
              text={DateConvert(new Date(data.tgl_retur_penjualan)).detail}
            />
          </Col>
          <Col lg="6">
            <ShowData title="No. Retur Penjualan" text={data.no_retur_penjualan} />
          </Col>
        </Row>
        <div className="my-3">
          <Col lg="12 pl-0">
            <ShowData title="Customer" text={data.nama_customer} />
          </Col>
          <Col lg="12 pl-0">
            <ShowData title="Sales" text={data.nama_sales} />
          </Col>
        </div>
        <hr />
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col lg="6">
                  <Input
                    label="Tanggal Penerimaan Retur"
                    type="date"
                    name="tgl_penerimaan_retur"
                    onChange={(e) => {
                      const value = e.target.value;

                      setFieldValue("tgl_penerimaan_retur", value);
                      getNomor(value, setFieldValue);
                    }}
                    error={errors.tgl_penerimaan_retur && touched.tgl_penerimaan_retur && true}
                    errorText={errors.tgl_penerimaan_retur}
                    value={data.tgl_retur_penjualan}
                  />
                </Col>
                <Col lg="6">
                  <Input
                    label="No. Penerimaan Retur"
                    type="text"
                    name="no_penerimaan_retur"
                    placeholder={
                      isNomorLoading
                        ? "Memuat . . . "
                        : values.tgl_penerimaan_retur
                          ? kode
                          : "Pilih tanggal terlebih dahulu"
                    }
                    readOnly={true}
                    onChange={(e) => {
                      setFieldValue("no_penerimaan_retur", e.target.value);
                    }}
                    value={data.no_retur_penjualan}
                  />
                </Col>
              </Row>
              <div className="mb-4"></div>
              <CRUDLayout.Table>
                <THead>
                  <Tr className="text-center">
                    <ThFixed>No</ThFixed>
                    <Th>Kode Barang</Th>
                    <Th>Nama Barang</Th>
                    <Th>Satuan</Th>
                    <Th>Qty Retur</Th>
                    <Th>Gudang Tujuan</Th>
                  </Tr>
                </THead>
                <TBody>
                  {data?.detail?.map((val, index) => (
                    <Tr key={index}>
                      <TdFixed>{index + 1}</TdFixed>
                      <TdFixed>{val.kode_item}</TdFixed>
                      <Td>{val.nama_item}</Td>
                      <Td>{val.nama_satuan}</Td>
                      <Td>{val.qty_retur_penjualan}</Td>
                      <Td>{val.nama_gudang}</Td>
                    </Tr>
                  ))}
                </TBody>
              </CRUDLayout.Table>
              <div className="d-flex justify-content-end">
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Simpan"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  };

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between">
        <span>Edit Penerimaan Retur</span>

        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
      </Card.Header>
      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : data !== undefined ? (
          <TambahData />
        ) : (
          <DataStatus text="Tidak ada data" />
        )}
      </Card.Body>
    </Card>
  );
};

export default EditPenerimaanRetur;
