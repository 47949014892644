// React
import { useState, useEffect } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"

// Component
import { Card } from "react-bootstrap"
import { Alert, BackButton, DataStatus } from "components"
import { DateConvert } from "utilities"

// Form
import { Formik } from "formik"
import * as Yup from "yup"

// API
import { PenerimaanSuratJalanApi } from 'api'

// Page Component
import {
  InfoDOSection, InfoSection, FormSection,
  InfoSJSection
} from "./Components"

const Tambah = ({setNavbarTitle}) => {
  const today = DateConvert(new Date()).default
  const history = useHistory()
  const location = useLocation()
  const {id} = useParams()
  console.log("id_satu", id)
  const [dataInfo, setDataInfo] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: false,
    success: true
  })
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: ""
  })

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false
    })

    PenerimaanSuratJalanApi.getSingle({ id_surat_jalan: id })
      .then(data => {
        const getData = data.data.data
        setDataInfo(getData)
        setDataTable(getData.detail)
        setFetchingStatus({
          loading: false,
          success: true
        })
      })
      .catch(() => {
        setAlertConfig({
          show: false,
          variant: 'primary',
          text: 'Data gagal dimuat!'
        })
        setFetchingStatus({
          loading: false,
          success: false
        })
      })
  }

  const formInitialValues = {
    id_surat_jalan: id,
    tgl_penerimaan_surat_jalan: today
  }

  const formValidationSchema = Yup.object().shape({ tgl_penerimaan_surat_jalan: Yup.string().required("Pilih tanggal") })

  const formSubmitHandler = (values, {setSubmitting}) => {
    PenerimaanSuratJalanApi.saveData(values)
      .then(() => {
        history.push("/inventory/transaksi/penerimaan-surat-jalan", {
          alert: {
            show: true,
            variant: "primary",
            text: "Data berhasil disimpan!"
          }
        })
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal disimpan!"
        })
      })
      .finally(() => setSubmitting(false))
  }

  useEffect(() => {
    getInitialData()
    setNavbarTitle("Penerimaan Surat Jalan")

    // eslint-disable-next-line
  }, [])

  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={formValidationSchema}
      onSubmit={formSubmitHandler}
    >
      {formik => (
        <Card style={{minHeight: "80vh"}}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Tambah Data Penerimaan Surat Jalan</b>
            <BackButton onClick={() => history.push("/inventory/transaksi/penerimaan-surat-jalan/list-surat-jalan", { ...location?.state })} />
          </Card.Header>
          <Card.Body className="pb-5">
            <Alert
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              showCloseButton={true}
              onClose={() => setAlertConfig({
                ...alertConfig,
                show: false
              })}
            />

            {fetchingStatus.loading ? (
              <DataStatus loading text="Memuat . . ." />
            ) : fetchingStatus.success ? (
              <form onSubmit={formik.handleSubmit}>
                <InfoSection data={dataInfo} />
                <InfoDOSection data={dataInfo} />
                <InfoSJSection data={dataInfo} /><hr />
                <FormSection formik={formik} dataTable={dataTable} />
              </form>
            ) : (
              <DataStatus text="Data gagal dimuat" />
            )}
          </Card.Body>
        </Card>
      )}
    </Formik>
  )
}

export default Tambah
