import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { ButtonGroup } from "react-bootstrap"
import { IoEyeOutline, IoAddOutline } from "react-icons/io5"
import axios from "axios"
import {
  CRUDLayout,
  InputSearch,
  ActionButton,
  FilterButton,
  BackButton,
  TdFixed,
  DataStatus,
  Tr,
  ThFixed,
  Alert,
  Pagination,
  Th,
  Td,
  THead,
  TBody,
} from "components"
import { TableNumber, DateConvert } from "utilities"
import { PenerimaanBarangApi } from "api"
import { ModalFilterPurchaseOrder, ModalDetailPurchaseOrder } from "./Section"

const ListPurchaseOrder = ({ setNavbarTitle }) => {
  const history = useHistory()
  const location = useLocation()
  const SESSION_KEY = {
    FILTER: `${location.pathname}_filter`,
    PAGINATION: `${location.pathname}_pagination`
  }
  const filter = sessionStorage.getItem(SESSION_KEY.FILTER) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.FILTER)) : {}
  const pagination = sessionStorage.getItem(SESSION_KEY.PAGINATION) ? JSON.parse(sessionStorage.getItem(SESSION_KEY.PAGINATION)) : {}

  let registerAlertConfig = location?.state?.registerAlertConfig
  const [isLoading, setIsLoading] = useState(false)
  const [searchConfig, setSearchConfig] = useState({ status: false, key: "" })
  const [data, setData] = useState([])
  const [showAlert, setShowAlert] = useState(false)
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
  })
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "filter",
    data: {}
  })
  const [dataFilter, setDataFilter] = useState({
    filter: {
      active: filter?.active,
      tgl_purchase_order_mulai: filter?.tgl_purchase_order_mulai,
      tgl_purchase_order_selesai: filter?.tgl_purchase_order_selesai,
      vendor: filter?.vendor,
      item_barang: filter?.item_barang,
    },
    pagination: {
      page: pagination?.page ?? "1",
      dataLength: pagination?.dataLength ?? "10",
      totalPage: pagination?.totalPage ?? "1",
      dataCount: pagination?.dataCount ?? "0",
    }

  })
  const [dropdown, setDropdown] = useState({
    item_barang: [{ value: undefined, label: 'Semua' },],
    vendor: [{ value: undefined, label: 'Semua' },],
  })

  const getData = () => {
    setIsLoading(true)

    PenerimaanBarangApi.getPagePO({
      q: searchConfig.key,
      page: dataFilter?.pagination?.page,
      per_page: dataFilter?.pagination?.dataLength,
      ...dataFilter?.filter
    })
      .then((res) => {
        setData(res.data.data)
        setDataFilter({
          ...dataFilter,
          pagination: {
            ...dataFilter.pagination,
            dataCount: res?.data.data_count,
            totalPage: res?.data?.total_page
          }
        })
      })
      .catch(() => {
        setAlertConfig({ variant: "danger", text: "Data gagal dimuat!" })
        setShowAlert(true)
      })
      .finally(() => setIsLoading(false))
  }

  // fetch dropdown filter
  const fetchDropdown = () => {
    axios.all([
      PenerimaanBarangApi.dropdownItemBarang(),
      PenerimaanBarangApi.dropdownVendor(),
    ])
      .then(axios.spread((resItem, resVendor) => {
        const mapItem = resItem.data.data.map((item) => ({ value: item.id_item_buaso, label: item.nama_item }))
        const mapVendor = resVendor.data.data.map(item => ({ value: item.id_vendor, label: item.nama_vendor }))

        setDropdown((prev) => ({
          ...prev,
          item_barang: prev.item_barang.concat(mapItem),
          vendor: prev.vendor.concat(mapVendor),
        }))
      }))
  }

  const onInputSearchChange = (e) => {
    const key = e.target.value

    setSearchConfig({
      ...searchConfig,
      key: e.target.value,
    })
    setDataFilter({
      ...dataFilter,
      pagination: {
        ...dataFilter.pagination,
        page: "1",
        dataLength: "10",
        totalPage: "1",
        dataCount: "0",
      }
    })
    setAlertConfig({
      variant: "primary",
      text: "Hasil dari pencarian: " + key,
    })
    setShowAlert(key ? true : false)
  }

  useEffect(() => {
    fetchDropdown()

    return () => setDropdown({ item_barang: [], vendor: [] })
  }, [])

  useEffect(() => {
    setNavbarTitle("Penerimaan Barang")
    getData()

    if (registerAlertConfig !== undefined) {
      setAlertConfig(registerAlertConfig)
      setShowAlert(true)
    }

    return () => { setIsLoading(false) }
  }, [setNavbarTitle, searchConfig.key, dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, dataFilter?.filter])

  // Tabel
  const Table = () => (
    <>
      <div className="font-weight-bold mb-2">List PO Yang Siap Dibuatkan Penerimaan Barang</div>
      <CRUDLayout.Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Purchase Order</ThFixed>
            <Th width={200}>Vendor</Th>
            <Th>Item Barang</Th>
            <Th width={100}>Qty. Purchase Order</Th>
            <Th width={100}>Qty. Diterima</Th>
            <Th width={100}>Qty. Sisa</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={val.id_purchase_order_detail + index}>
              <TdFixed>{TableNumber(dataFilter?.pagination?.page, dataFilter?.pagination?.dataLength, index)}</TdFixed>
              <Td>
                <div> {DateConvert(new Date(val.tgl_purchase_order)).defaultDMY}</div>
                <div> {val.no_purchase_order} </div>
              </Td>
              <Td>{val.nama_vendor}</Td>
              <Td> {val.nama_item} </Td>
              <Td>
                <div className="text-right">
                  {val.qty_order ? `${parseFloat(val.qty_order)} ${val.nama_satuan_beli ?? ""}` : "-"}
                </div>
              </Td>
              <Td>
                <div className="text-right">
                  {val.qty_diterima ? `${parseFloat(val.qty_diterima)} ${val.nama_satuan_pakai ?? ""}` : "-"}
                </div>
              </Td>
              <Td>
                <div className="text-right">
                  {val.qty_sisa ? `${parseFloat(val.qty_sisa)} ${val.nama_satuan_pakai ?? ""}` : "-"} </div>
              </Td>
              <TdFixed>
                <ButtonGroup>
                  <ActionButton
                    tooltip
                    tooltipText="Detail Purchase Order"
                    variant="primary"
                    size="sm"
                    onClick={() => setModalConfig({ show: true, type: "detail", data: val })} >
                    <IoEyeOutline />
                  </ActionButton>
                  <ActionButton
                    tooltip
                    tooltipText="Tambah Penerimaan Barang"
                    variant="primary"
                    size="sm"
                    onClick={() => {
                      //  STORE STATE TO SESSION
                      sessionStorage.setItem(SESSION_KEY.FILTER, JSON.stringify(dataFilter?.filter))
                      sessionStorage.setItem(SESSION_KEY.PAGINATION, JSON.stringify(dataFilter?.pagination))

                      history.push(`/inventory/transaksi/penerimaan-barang/tambah/${val.id_purchase_order_detail}`)
                    }} >
                    <IoAddOutline />
                  </ActionButton>
                </ButtonGroup>
              </TdFixed>
            </Tr>
          ))}
        </TBody>
      </CRUDLayout.Table>
      <Pagination
        dataLength={dataFilter?.pagination?.dataLength}
        dataNumber={
          dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength - dataFilter?.pagination?.dataLength + 1
        }
        dataPage={
          dataFilter?.pagination?.dataCount < dataFilter?.pagination?.dataLength
            ? dataFilter?.pagination?.dataCount
            : dataFilter?.pagination?.page * dataFilter?.pagination?.dataLength
        }
        dataCount={dataFilter?.pagination?.dataCount}
        currentPage={dataFilter?.pagination?.page}
        totalPage={dataFilter?.pagination?.totalPage}
        onPaginationChange={({ selected }) =>
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              page: selected + 1,
            }
          })
        }
        onDataLengthChange={(e) =>
          setDataFilter({
            ...dataFilter,
            pagination: {
              ...dataFilter.pagination,
              page: 1,
              dataLength: e.target.value,
            }
          })
        }
      />
    </>
  )

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch value={searchConfig.key} onChange={onInputSearchChange} />
            <FilterButton
              active={dataFilter?.filter?.active}
              onClick={() => setModalConfig({ show: true, type: 'filter' })}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* ALert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => {
          setShowAlert(false)
          // CLEAR HISTORY LOCATION
          history.replace("/transaksi/analisa-barang-jadi", { registerAlertConfig: undefined })
        }}
      />

      {/* Table Section */}
      {isLoading === true
        ? (<DataStatus loading={true} text="Memuat data..." />)
        : data && data?.length > 0
          ? (<Table />)
          : (<DataStatus text="Tidak ada data" />)
      }

      {/* Modal Filter  */}
      {modalConfig.show && modalConfig.type === "filter" && (
        <ModalFilterPurchaseOrder
          SESSION_KEY={SESSION_KEY}
          dropdown={dropdown}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
          data={dataFilter}
          setData={setDataFilter}
        />
      )}

      {/* Modal Detail */}
      {modalConfig.show && modalConfig.type === 'detail' && (
        <ModalDetailPurchaseOrder
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      )}
    </CRUDLayout>
  )
}

export default ListPurchaseOrder
