import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import {
  CRUDLayout,
  Alert,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  ActionButton,
  Input,
  BackButton,
  DataStatus,
  TextArea,
  Select,
  SelectSearch,
  DeleteButton,
} from "../../../../components";
import { PurchaseRequestApi } from "../../../../api";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import { Formik } from "formik";

const TambahPurchaseRequest = ({ setNavbarTitle }) => {
  // indikator pemanggilan data sedang dimuat di server (loading)
  // const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  // data
  const [dataNo, setDataNo] = useState("");
  const [list, setList] = useState([]);
  const [dataKelompok, setDataKelompok] = useState([]);

  const [data, setData] = useState({
    jenis: [],
    item: [],
  });

  const [jenis, setJenis] = useState([]);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState({
    jenis: false,
    item: false,
  });

  // menampilkan alert
  const [showAlert, setShowAlert] = useState(false);
  // configurasi alert
  const [alertConfig, setAlertConfig] = useState({
    variant: "primary",
    text: "",
    status: false,
  });

  // request data dari server
  const getData = () => {
    setIsLoading(true);

    PurchaseRequestApi.getKelompok()
      .then((res) => setDataKelompok(res.data.data))
      .catch(() => {
        setAlertConfig({
          variant: "danger",
          text: "Data Kelompok gagal dimuat",
        });
        setShowAlert(true);
      })
      .finally(() => setIsLoading(false));
  };

  const getJenis = (id) => {
    setIsLoading({
      jenis: true,
      item: true,
    });

    PurchaseRequestApi.getJenis(id)
      .then((res) => setJenis(res.data.data))
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: "Data Jenis Tidak Ada",
        });
        setShowAlert(true);
      })
      .finally(
        () =>
          setIsLoading({
            jenis: false,
            item: false,
          }),
        setShowAlert(false)
      );
  };

  const getItem = (id) => {
    setIsLoading({
      item: true,
    });
    PurchaseRequestApi.getItem(id)
      .then((res) => setItem(res.data.data))
      .catch((err) => {
        setAlertConfig({
          variant: "danger",
          text: "Data Item Tidak Ada",
        });
        setShowAlert(true);
        setItem([]);
      })
      .finally(
        () =>
          setIsLoading({
            item: false,
          }),
        setShowAlert(false)
      );
  };

  const getNomor = (id) => {
    const date = Date.parse(id);

    if (isNaN(date) == false) {
      var dataDate = new Date(date).toISOString().split("T")[0].split("-").join("/");
      PurchaseRequestApi.getNoSurat(dataDate)
        .then((res) => {
          setDataNo(res.data.data);
        })
        .catch((err) => alert(err));
    }
  };

  const getList = (id, kode, nama, satuan, qty) => {
    if (id != null && qty != null) {
      setList([
        ...list,
        {
          id_item_buaso: id,
          kode_item: kode,
          nama_item: nama,
          satuan_beli: satuan,
          qty: qty,
        },
      ]);
    } else {
      alert("Item dan Qty tidak boleh kosong");
    }
  };

  const deleteList = (index) => {
    const array = [...list];
    array.splice(index, 1);
    setList(array);
  };

  useEffect(() => {
    // set Judul di Navbar
    setNavbarTitle("Tambah Purchase Request");

    // jalankan function request data ke server
    getData();

    return () => {
      setIsLoading(false);
    };
  }, [setNavbarTitle]);

  const formInitialValues = {
    no_purchase_request: "",
    tgl_purchase_request: "",
    keperluan: "",
    catatan_purchase_request: "",
    tgl_pemakaian: "",
  };

  const formValidationSchema = Yup.object().shape({
    tgl_purchase_request: Yup.date().required("Masukkan Tanggal Purchase Request"),
    tgl_pemakaian: Yup.date().required("Masukan Tanggal Pemakaian"),
    keperluan: Yup.string().required("Pilih Keperluan"),
  });

  // KIRIM DATA BARU KE SERVER
  const formSubmitHandler = (values) => {
    if (list.length > 0) {
      const finalValues = {
        ...values,
        detail: list,
      };

      PurchaseRequestApi.create(finalValues)
        .then(() => {
          history.push("/inventory/transaksi/purchase-request", {
            variant: "primary",
            text: "Purchase Request berhasil ditambah",
            status: true,
          });
        })
        .catch((err) => {
          setAlertConfig({
            variant: "danger",
            text: `Tambah Purchase Request gagal! (${err.response.data.message})`,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setShowAlert(true);
      setAlertConfig({
        variant: "danger",
        text: "List Item Purchase Request wajib diisi minimal 1",
      });
    }
  };

  const decimalConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, "");
    const convert = newValue.substring(0, 7);

    return convert;
  };

  const ShowData = ({ title, text }) => (
    <div className="d-flex flex-row">
      <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
        {title}
      </div>
      <span>:</span>
      <div
        className="text-dark"
        style={{
          fontWeight: 200,
          paddingLeft: 40,
        }}
      >
        {text ? text : "-"}
      </div>
    </div>
  );

  return (
    <Card>
      <Card.Header className="d-flex flex-row justify-content-between">
        <span>Tambah Purchase Request</span>

        <BackButton
          onClick={() => {
            history.push("/inventory/transaksi/purchase-request", {...location?.state});
          }}
        />
      </Card.Header>
      <Card.Body>
        <Alert
          show={showAlert}
          showCloseButton={true}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setShowAlert(false)}
        />
        {isLoading === true ? (
          <DataStatus loading={true} text="Memuat data..." />
        ) : (
          <Formik
            initialValues={formInitialValues}
            validationSchema={formValidationSchema}
            onSubmit={formSubmitHandler}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              setFieldValue,
              handleChange,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-4 p-0">
                  <Row>
                    <Col lg="6">
                      <Input
                        label="Tanggal Purchase Request"
                        type="date"
                        name="tgl_purchase_request"
                        onChange={(e) => {
                          const value = e.target.value;

                          setFieldValue("tgl_purchase_request", value);
                          setFieldValue("no_purchase_request", getNomor(value));
                        }}
                        onBlur={() => {
                          setFieldValue("no_purchase_request", dataNo);
                        }}
                        error={errors.tgl_purchase_request && touched.tgl_purchase_request && true}
                        errorText={errors.tgl_purchase_request}
                      />
                    </Col>

                    <Col lg="6">
                      <Input
                        label="No. Purchase Request"
                        name="no_purchase_request"
                        value={values.no_purchase_request}
                        readOnly={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col lg="6">
                      <Input
                        label="Tanggal Pemakaian"
                        type="date"
                        name="tgl_pemakaian"
                        onChange={handleChange}
                        error={errors.tgl_pemakaian && touched.tgl_pemakaian && true}
                        errorText={errors.tgl_pemakaian}
                      />
                    </Col>

                    <Col lg="6">
                      <Select
                        label="Keperluan"
                        name="keperluan"
                        onChange={(e) => {
                          const value = e.target.value;

                          setFieldValue("keperluan", value);
                        }}
                        error={errors.keperluan && touched.keperluan && true}
                        errorText={errors.keperluan}
                      >
                        <option value="" hidden>
                          Pilih Keperluan
                        </option>
                        <option value="stok">Stok</option>
                        <option value="produksi">Produksi</option>
                      </Select>
                    </Col>
                  </Row>

                  <TextArea
                    label="Catatan"
                    type="text"
                    name="catatan_purchase_request"
                    rows={2}
                    onChange={handleChange}
                  />

                  <Row>
                    <Col lg="2">
                      <SelectSearch
                        label="Kelompok"
                        name="id_kelompok"
                        placeholder="Pilih Kelompok"
                        onChange={(val) => {
                          setFieldValue("id_kelompok", val.value);
                          setFieldValue("id_jenis", "");
                          setFieldValue("id_item_buaso", "");
                          getJenis(val.value);
                        }}
                        option={dataKelompok.map((val) => {
                          return {
                            value: val.id_kelompok,
                            label: val.nama_kelompok,
                          };
                        })}
                      />
                    </Col>

                    <Col lg="2">
                      <SelectSearch
                        label="Jenis"
                        name="id_jenis"
                        placeholder="Pilih Jenis"
                        onChange={(val) => {
                          setFieldValue("id_jenis", val.value);
                          setFieldValue("id_item_buaso", "");
                          getItem(val.value);
                        }}
                        option={jenis.map((val) => {
                          return {
                            value: val.id_jenis,
                            label: val.nama_jenis,
                          };
                        })}
                        loading={isLoading.jenis}
                        isDisabled={values.id_kelompok ? false : true}
                      />
                    </Col>

                    <Col lg="3">
                      {/* <SelectSearch
                        label="Item"
                        name="id_item"
                        placeholder="Pilih Item"
                        onChange={(val) => {
                          setFieldValue("id_item", val.value);
                          setFieldValue("nama_satuan", val.satuan_beli);
                          setFieldValue("kode_item", val.kode_item);
                          setFieldValue("nama_item", val.label);
                        }}
                        option={item.map((val) => {
                          return {
                            value: val.id_item_buaso,
                            label:
                              val.dimensi === "3"
                                ? `${val.nama_item} (${val.pj} X ${val.lb} X ${val.tb})`
                                : val.dimensi === "0"
                                  ? val.nama_item
                                  : val.dimensi === "1"
                                    ? `${val.nama_item} (${val.pj})`
                                    : val.dimensi === "2"
                                      ? `${val.nama_item} (${val.pj} X ${val.lb})`
                                      : "",
                            satuan_beli: val.satuan_beli,
                            kode_item: val.kode_item,
                          };
                        })}
                        loading={isLoading.item}
                        isDisabled={values.id_jenis ? false : true}
                      /> */}
                      <SelectSearch
                        label="Item"
                        name="id_item"
                        placeholder="Pilih Item"
                        onChange={(val) => {
                          setFieldValue("id_item", val.value);
                          setFieldValue("nama_satuan", val.satuan_beli);
                          setFieldValue("kode_item", val.kode_item);
                          setFieldValue("nama_item", val.label);
                        }}
                        option={item.map((val) => {
                          return {
                            value: val.id_item_buaso,
                            label: val.nama_item,
                            satuan_beli: val.satuan_beli,
                            kode_item: val.kode_item,
                          };
                        })}
                        loading={isLoading.item}
                        isDisabled={values.id_jenis ? false : true}
                      />
                    </Col>

                    <Col lg="2">
                      <Input
                        label="Satuan"
                        name="nama_satuan"
                        value={values.nama_satuan}
                        readOnly={true}
                      />
                    </Col>

                    <Col lg="1">
                      <Input
                        label="Qty"
                        type="text"
                        name="qty"
                        value={values.qty}
                        onChange={(e) => {
                          const value = e.target.value;
                          const convert = decimalConvert(value);
                          setFieldValue("qty", convert);
                        }}
                      />
                    </Col>

                    <Col lg="1">
                      <small style={{ visibility: "hidden" }}>test</small>
                      <ActionButton
                        onClick={(e) => {
                          e.preventDefault();

                          getList(
                            values.id_item,
                            values.kode_item,
                            values.nama_item,
                            values.nama_satuan,
                            values.qty
                          );
                        }}
                        variant="primary"
                        text="Tambah"
                      />
                    </Col>
                  </Row>
                </div>

                <small>List Item Purchase Request</small>
                <CRUDLayout.Table>
                  <THead>
                    <Tr className="text-center">
                      <ThFixed>No</ThFixed>
                      <Th>Kode Item</Th>
                      <Th>Nama Item</Th>
                      <Th>Satuan</Th>
                      <Th>Qty</Th>
                      <Th>Aksi</Th>
                    </Tr>
                  </THead>
                  <TBody>
                    {list.map((val, index) => (
                      <Tr key={index}>
                        <TdFixed>{index + 1}</TdFixed>
                        <TdFixed>{val.kode_item}</TdFixed>
                        <Td>{val.nama_item}</Td>
                        <Td>{val.satuan_beli}</Td>
                        <Td>
                          <div className="text-right">{val.qty}</div>
                        </Td>
                        <TdFixed>
                          <DeleteButton
                            onClick={() => {
                              deleteList(index);
                            }}
                          />
                        </TdFixed>
                      </Tr>
                    ))}
                  </TBody>
                </CRUDLayout.Table>
                <div className="d-flex justify-content-end mb-4">
                  <ActionButton
                    type="submit"
                    variant="primary"
                    text="Simpan"
                    className="mt-2 px-4"
                  />
                </div>
              </form>
            )}
          </Formik>
        )}
      </Card.Body>
    </Card>
  );
};

export default TambahPurchaseRequest;
