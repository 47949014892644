import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from "react-router-dom"
import {
    Input,
    TextArea,
    ActionButton,
    DatePicker,
    NumberFormat,
    CRUDLayout,
    BackButton,
    Alert,
    DataStatus
} from 'components'
import { Row, Col, Card } from 'react-bootstrap'
import { TabelPenerimaanBerasUpdate } from "./__Section__"
import { useFormik } from "formik"
import * as Yup from "yup"
import { PenerimaanBerasApi } from 'api'
import Axios from "axios"
import { DecimalConvert } from 'utilities'

const FormField = ({ formik }) => {
    const [isNomorLoading, setIsNomorLoading] = useState(false)
    const { values, errors, touched, setFieldValue, handleChange } = formik
    
    return (
      <>
        <Row>
          <Col md={3}>
            <DatePicker
              label="Tgl. Penerimaan Beras"
              placeholderText="Pilih tanggal"
              dateFormat="dd/MM/yyyy"
              selected={values.tgl_penerimaan_beras ? new Date(values.tgl_penerimaan_beras) : ""}
              onChange={date => {
                const newDate = date.toISOString().slice(0, 10)
                setFieldValue("tgl_penerimaan_beras", newDate)
                // getNomorHandler(newDate)
              }}
            />
          </Col>
          <Col md={3}>
            <Input
              label="No. Penerimaan Beras"
              placeholder={isNomorLoading ? "Memuat nomor . . ." : "Pilih tanggal untuk menentukan nomor"}
              value={isNomorLoading ? "Memuat nomor . . ." : values.no_penerimaan_beras}
              readOnly={true}
              onChange={handleChange}
            />
          </Col>
          <Col md={3}>
            <Input
              label="Supir"
              type="text"
              name="supir"
              placeholder="Masukkan nama supir"
              value={values.supir}
              onChange={handleChange}
              error={Boolean(errors.supir && touched.supir && true)}
              errorText={Boolean(errors.supir)}
            />
          </Col>
          <Col md={3}>
            <Input
              label="Plat Kendaraan"
              type="text"
              name="plat_kendaraan"
              placeholder="Masukkan no plat kendaraan"
              value={values.plat_kendaraan}
              onChange={handleChange}
              error={errors.plat_kendaraan && touched.plat_kendaraan && true}
              errorText={errors.plat_kendaraan}
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <NumberFormat
              className={values.berat_truk_masuk && "text-right"}
              label='Berat Truk Masuk (kg)'
              placeholder='Masukkan berat awal'
              value={DecimalConvert(values.berat_truk_masuk).getWithComa}
              defaultValue={DecimalConvert(values.berat_truk_masuk).getWithComa}
              onChange={(val) => {setFieldValue("berat_truk_masuk", val)}}
              error={errors.berat_truk_masuk && touched.berat_truk_masuk && true}
              errorText={errors.berat_truk_masuk && touched.berat_truk_masuk && errors.berat_truk_masuk}
            />
          </Col>
          <Col md={3}>
            <NumberFormat
              className={values.berat_truk_keluar && "text-right"}
              label='Berat Truk Keluar (kg)'
              placeholder='Masukkan berat awal'
              value={DecimalConvert(values.berat_truk_keluar).getWithComa}
              defaultValue={DecimalConvert(values.berat_truk_keluar).getWithComa}
              onChange={(val) => {
                setFieldValue("berat_truk_keluar", val)
                setFieldValue("berat_gabah_kering_giling", values.berat_truk_masuk - val)
              }}
              error={errors.berat_truk_keluar && touched.berat_truk_keluar && true}
              errorText={errors.berat_truk_keluar && touched.berat_truk_keluar && errors.berat_truk_masuk}
            />
          </Col>
          <Col md={3}>
            <NumberFormat
              className="text-right"
              label='Total Muatan Truk (kg)'
              placeholder='Masukkan harga satuan untuk menentukan total harga'
              disabled={true}
              value={values?.berat_gabah_kering_giling ? DecimalConvert(values.berat_gabah_kering_giling).getWithComa : DecimalConvert(values.berat_gkg).getWithComa}
            />
          </Col>
        </Row>
      </>
    )
}
const KeteranganField = ({ formik }) => {
    const { values, handleChange } = formik
    return(
        <>
            <div className="font-weight-bold mt-4 mb-2">Keterangan</div>
            <Row>
                <Col md={12}>
                    <TextArea
                        type="text"
                        name="keterangan"
                        value={values.keterangan}
                        rows={4}
                        onChange={handleChange}
                    />
                </Col>
            </Row>
        </>
    )
}
const ButtonSection = ({ formik }) => {
    const { handleSubmit, isSubmitting } = formik

    return(
        <div className="d-flex justify-content-end my-3">
        <ActionButton
            className="px-4"
            // type="submit"
            variant="primary"
            text="UBAH"
            onClick={handleSubmit}
            loading={isSubmitting}
        />
        </div>
    )
}

const PenerimaanBerasUpdate = ({ setNavbarTitle }) => {
    const history = useHistory()
    const location = useLocation()
    const { id } = useParams()

    const [dropdownItem, setDropdownItem] = useState([])
    const [dropdownGudang, setDropdownGudang] = useState([])
    const [readData, setReadData] = useState([])
    const [readDataForm, setReadDataForm] = useState([])
    const [dataDelete, setDataDelete] = useState([])

    const [loading, setLoading] = useState(false)

    const [alertConfig, setAlertConfig] = useState({
        variant: "primary",
        text: "",
        status: false,
    })

    const getInitialData = () => {
        setLoading(true)

        Axios.all([
        PenerimaanBerasApi.dropdownItem(),
        PenerimaanBerasApi.dropdownGudang(),
        PenerimaanBerasApi.getSingle({ id_penerimaan_beras: id })
        ])
        .then(
            Axios.spread((item, gudang, read) => {
            const mapDataPenerimaanBeras = item?.data?.data 
                ? item.data.data.map(val => ({
                    // label: `${val?.kode_item} | ${val?.nama_item}`, 
                    label: val?.nama_item,
                    value: val?.id_item_buaso,
                    satuanLabel: val?.nama_satuan,
                    satuanValue: val?.id_satuan,
                    kodeItem: val.kode_item,
                    itemLabel: val.nama_item,
                })) 
                : []
            const mapDataGudang = gudang?.data?.data ? gudang.data.data.map(gdg => ({ label: gdg.nama_gudang, value: gdg.id_gudang })) : []
            const readDataPB = read?.data?.data?.detail ? read?.data?.data?.detail : []
            const filterDataPB = readDataPB.map(val => ({
                harga_satuan: val.harga_satuan,
                id_gudang: val.id_gudang,
                id_satuan_pakai: val.id_satuan_pakai,
                id_item_buaso: val.id_item_buaso,
                id_penerimaan_beras: val.id_penerimaan_beras,
                id_penerimaan_beras_detail: val.id_penerimaan_beras_detail,
                nama_gudang: val.nama_gudang,
                nama_item: val.nama_item,
                nama_satuan: val.nama_satuan,
                kode_item: val.kode_item,
                qty_penerimaan: val.qty_penerimaan,
                total_harga: val.total_harga
            }))

            setDropdownItem([ ...dropdownItem, ...mapDataPenerimaanBeras ])
            setDropdownGudang([ ...dropdownGudang, ...mapDataGudang ])
            setReadData(filterDataPB)
            setReadDataForm(read?.data?.data ?? [])
          })
        )
        .finally(() => setLoading(false))
    }

// FORM VALUES
    const formInitialValues = {
      id_penerimaan_beras: readDataForm?.id_penerimaan_beras ?? "",
      id_detail_deleted: dataDelete ?? "",
      tgl_penerimaan_beras: readDataForm?.tgl_penerimaan_beras ? new Date(readDataForm?.tgl_penerimaan_beras) : "",
      no_penerimaan_beras: readDataForm?.no_penerimaan_beras ?? "",
      supir: readDataForm?.supir ?? "",
      plat_kendaraan: readDataForm?.plat_kendaraan ?? "",
      berat_truk_masuk: readDataForm?.berat_truk_masuk ?? "",
      berat_truk_keluar: readDataForm?.berat_truk_keluar ?? "",
      berat_gabah_kering_giling: "",
      berat_gkg: readDataForm?.berat_truk_masuk - readDataForm?.berat_truk_keluar ?? "",
      keterangan: readDataForm?.keterangan ?? "",
    }
    const formValidationSchema = Yup.object().shape({
        supir: Yup.string().required("Masukkan nama supir"),
        plat_kendaraan: Yup.string().required("Masukkan nomor plat kendaraan"),
        berat_truk_masuk: Yup.string().required("Pilih berat truk masuk"),
        berat_truk_keluar: Yup.string().required("Pilih berat truk keluar"),
    })
    const formSubmitHandler = (values) => {
        const finalValues = {
            ...values,
            detail: readData.map(val => ({
              id_penerimaan_beras_detail: val.id_penerimaan_beras_detail,
              id_item_buaso: val.id_item_buaso,
              id_satuan_pakai: val.id_satuan_pakai,
              qty_penerimaan: val.qty_penerimaan,
              id_gudang: val.id_gudang,
              harga_satuan: val.harga_satuan
            }))
        }
        PenerimaanBerasApi.update(finalValues)
        .then(() =>
            history.push("/inventory/transaksi/penerimaan-beras", {
            alert: {
              show: true,
              variant: "primary",
              text: "Data berhasil ditambah!",
            },
            ...location?.state
            })
        )
        .catch((err) => {
          setAlertConfig({
            show: true,
            type: "danger",
            text: `Tambah Penerimaan Beras gagal! (${err?.response?.data?.message})`,
          })
        })
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formInitialValues,
        validationSchema: formValidationSchema,
        onSubmit: formSubmitHandler,
    })

    useEffect(() => {
        setNavbarTitle("Penerimaan Beras")
        getInitialData()
    }, [])

    return (
        <CRUDLayout>
            <CRUDLayout.Head>
                <CRUDLayout.HeadButtonSection>
                    <BackButton onClick={() => history.push("/inventory/transaksi/penerimaan-beras", { ...location?.state })}/>
                </CRUDLayout.HeadButtonSection>
            </CRUDLayout.Head>
            <div className="font-weight-bold mb-2">Ubah Data Penerimaan Beras</div>
            <Card>
                {loading
                    ?   <DataStatus loading={true} text="Memuat data . . ." />
                    :   <div className="p-3">
                            <FormField formik={formik} />
                            <Alert
                                show={alertConfig.status}
                                showCloseButton={true}
                                variant={alertConfig.variant}
                                text={alertConfig.text}
                                onClose={() => setAlertConfig({ ...alertConfig, status: false })}
                            />
                            <TabelPenerimaanBerasUpdate
                                dropdownItem={dropdownItem} 
                                dropdownGudang={dropdownGudang}
                                readData={readData}
                                setReadData={setReadData}
                                setDataDelete={setDataDelete}
                                isType="UPDATE"
                            />
                            <KeteranganField formik={formik} />
                            <ButtonSection formik={formik} />
                        </div>
                }
            </Card>
        </CRUDLayout>
    )
}

export default PenerimaanBerasUpdate