import Logo from "../assets/image/logoPuspa.png"
import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoPersonCircleOutline,
  IoDocumentTextOutline,
} from "react-icons/io5"

import {
  // Buaso
  Buaso,
  Satuan,
  Kelompok,
  Jenis,
  Pabrikan,
  Gudang,

  // DEPO
  Depo,

  // Registrasi Barang Jadi
  RegistrasiBarangJadi,
  TambahBarangJadi,
  UbahBarangJadi,
  DetailBarangJadi,

  // Registrasi BUASO
  RegistrasiItemBahan,
  RegistrasiAlatMesin,

  // Delivery Order
  DeliveryOrder,
  ListSo,
  TambahDeliveryOrder,
  EditDeliveryOrder,
  DetailDeliveryOrder,

  // Purchase Request
  PurchaseRequest,
  TambahPurchaseRequest,
  UbahPurchaseRequest,

  // Surat Jalan
  ListSuratJalan,
  ListDeliveryOrderSuratJalan,
  TambahSuratJalan,
  UbahSuratJalan,
  DetailSuratJalan,

  // Pengembalian Barang
  PengembalianBarang,
  TambahPengembalianBarang,
  EditPengembalianBarang,
  DetailPengembalianBarang,

  // Mapping Unit Produksi
  MappingUnitProduksi,

  // Penerimaan & Pembatalan Retur
  ListFakturDiBatalkan,
  PenerimaanRetur,
  ListReturPenjulan,
  TambahPenerimaanRetur,
  EditPenerimaanRetur,
  DetailPenerimaanRetur,

  // Penerimaan Barang
  PenerimaanBarang,
  ListPurchaseOrder,
  TambahPenerimaanBarang,
  UbahPenerimaanBarang,

  // Permintaan Bahan Produksi
  PermintaanBahanProduksi,
  DataPermintaanBahanProduksi,
  CetakPermintaanBahanProduksi,
  DetailCetakPermintaanProduksi,

  // Realisasi Sisa produksi
  RealisasiSisaProduksi,
  ListTransferSisaProduksi,

  // Penerimaan Surat Jalan
  PenerimaanSuratJalan,
  ListSuratJalanPenerimaan,
  TambahPenerimaanSuratJalan,
  DetailPenerimaanSuratJalan,

  // Mutasi Antar Gudang
  MutasiAntarGudang,

  // Transfer Bahan Job Mix
  TransferBahanJobMix,
  ListPermintaanBahanJobMix,
  TambahTransferBahanJobMix,

  //Setup Stok Awal
  SetupStokAwal,

  // Laporan
  KartuStockMaterial,
  LaporanPurchaseRequest,
  LaporanDeliveryOrder,
  StokTerbaru,
  KelompokAset,
  JeniAset,

  // Transfer Bahan Produksi
  TransferBahanProduksiList,
  TransferBahanPermintaanProduksiList,
  TransferBahanProduksiTambah,

  //Penerimaan Gabah Kering Giling
  PenerimaanGabahKeringGiling,

  //Penerimaan Beras
  PenerimaanBerasList,
  PenerimaanBerasDetail,
  PenerimaanBerasUpdate,
  PenerimaanBerasCreate

} from "../pages/Inventory"

export default {
  LOGO: Logo,
  MODUL: "Inventori",

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: "Dashboard",
      link: "/",
      type: "menu",
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ["SUPA", "INV"],
    },
    {
      text: "Master Data",
      type: "dropdown",
      icon: <IoServerOutline />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "BUASO",
          link: "/inventory/master/buaso",
          hak: ["SUPA", "INV_MAS_BSO"],
        },
        {
          text: "Satuan",
          link: "/inventory/master/satuan",
          hak: ["SUPA", "INV_MAS_STN"],
        },
        {
          text: "Kelompok Barang",
          link: "/inventory/master/kelompok",
          hak: ["SUPA", "INV_MAS_KLB"],
        },
        {
          text: "Jenis Barang",
          link: "/inventory/master/jenis-bahan",
          hak: ["SUPA", "INV_MAS_JNB"],
        },
        // {
        //   text: "Kelompok Alat & Mesin",
        //   link: "/inventory/master/kelompok-aset",
        //   hak: ["SUPA",  "INV_MAS_KEL"],
        // },
        // {
        //   text: "Jenis Alat & Mesin",
        //   link: "/inventory/master/jenis-aset",
        //   hak: ["SUPA",  "INV_MAS_JEN"],
        // },
        {
          text: "Item Barang",
          link: "/inventory/transaksi/register-item-bahan",
          hak: ["SUPA", "INV_MAS_IBH"],
        },
        {
          text: "Gudang",
          link: "/inventory/master/gudang",
          hak: ["SUPA", "INV_MAS_GUD"],
        },
        // {
        //   text: "Pabrikan",
        //   link: "/inventory/master/pabrikan",
        //   hak: ["SUPA", "INV", "INV_MAS_PAB"],
        // },

        // {
        //   text: "Item Alat dan Mesin",
        //   link: "/inventory/transaksi/register-alat-dan-mesin",
        //   hak: ["SUPA", "INV", "INV_REG_ALM"],
        // },
        // {
        //   text: "Barang Jadi",
        //   link: "/master/registrasi-barang-jadi",
        //   hak: ["SUPA", "PRD_MAS_BRJ"],
        // },
        {
          text: "Depo",
          link: "/inventory/master/depo",
          hak: ["SUPA", "INV_MAS_GUD"],
        },
      ],
    },
    {
      text: "Transaksi",
      type: "dropdown",
      icon: <IoCashOutline />,
      hak: ["SUPA", "INV"],
      menu: [
        // {
        //   text: "Comming Soon",
        //   link: "",
        //   hak: ["SUPA", "INV"],
        // },
        // {
        //   text: "Mapping Unit Produksi",
        //   link: "/inventory/transaksi/mapping-unit-produksi",
        //   hak: ["SUPA", "INV", "INV_REG_BHN"],
        // },
        {
          text: "Purchase Request",
          link: "/inventory/transaksi/purchase-request",
          hak: ["SUPA", "INV_TRN_PR"],
        },
        {
          text: "Penerimaan Barang",
          link: "/inventory/transaksi/penerimaan-barang",
          hak: ["SUPA", "INV_TRN_PNB"],
        },
        {
          text: "Penerimaan Gabah Kering Giling",
          link: "/inventory/transaksi/penerimaan-gabah-kering-giling",
          hak: ["SUPA", "INV_TRN_PGKG"],
        },
        {
          text: "Penerimaan Beras",
          link: "/inventory/transaksi/penerimaan-beras",
          hak: ["SUPA", "INV_TRN_PBS"],
        },
        // {
        //   text: "Delivery Order",
        //   link: "/inventory/transaksi/delivery-order",
        //   hak: ["SUPA", "INV_TRN_DO"],
        // },
        // {
        //   text: "Surat Jalan",
        //   link: "/inventory/transaksi/surat-jalan",
        //   hak: ["SUPA", "INV_TRN_SJ"],
        // },
        // // {
        // //   text: "Pengembalian Barang",
        // //   link: "/inventory/transaksi/pengembalian-barang",
        // //   hak: ["SUPA",  "INV_REG_OVH"],
        // // },
        // // {
        // //   text: "Penerimaan Retur",
        // //   link: "/inventory/transaksi/penerimaan-retur",
        // //   hak: ["SUPA", "INV", "INV_REG_OVH"],
        // // },
        // // {
        // //   text: "Transfer Bahan Produksi",
        // //   link: "/inventory/transaksi/transfer-produksi-bahan",
        // //   hak: ["SUPA", "INV", "INV_TRN_TPB"],
        // // },
        // {
        //   text: "Transfer Bahan Job Mix",
        //   link: "/inventory/transaksi/transfer-bahan-job-mix",
        //   hak: ["SUPA", "INV", "INV_TRN_TJB"],
        // },
        // {
        //   text: "Realisasi Sisa Produksi",
        //   link: "/inventory/transaksi/realisasi-sisa-produksi",
        //   hak: ["SUPA", "INV", "INV_TRN_RSP"],
        // },
        {
          text: "Transfer Bahan Produksi",
          link: "/inventory/transaksi/transfer-bahan-produksi",
          hak: ["SUPA", "INV", "INV_TRN_TBP"],
        },
        // {
        //   text: "Penerimaan Surat Jalan",
        //   link: "/inventory/transaksi/penerimaan-surat-jalan",
        //   hak: ["SUPA", "INV_TRN_PSJ"],
        // },
        {
          text: "Mutasi Antar Gudang",
          link: "/inventory/transaksi/mutasi-antar-gudang",
          hak: ["SUPA", "INV_TRN_MAG"],
        },
        {
          text: "Setup Stok",
          link: "/inventory/transaksi/setup-stok-awal",
          hak: ["SUPA", "INV", "INV_TRN_MAG"],
        },
      ],
    },
    {
      text: "Laporan",
      type: "dropdown",
      icon: <IoDocumentTextOutline />,
      hak: ["SUPA", "INV"],
      menu: [
        {
          text: "Kartu Stock Barang",
          link: "/inventory/laporan/kartu-stock-material",
          hak: ["SUPA", "INV_LAP_KST"],
        },
        {
          text: "Stok Terbaru",
          link: "/inventory/laporan/stok-terbaru",
          hak: ["SUPA", "INV_LAP_SUP"],
        },
        // {
        //   text: "Stock Update",
        //   link: "/inventory/laporan/stock-update",
        //   hak: ["SUPA", "INV_LAP_SUP"],
        // },
        // {
        //   text: "Purchase Request",
        //   link: "/inventory/laporan/purchase-request",
        //   hak: ["SUPA", "INV", "INV_RPT_LPR"],
        // },
        //     // {
        //     //   text: "Delivery Order",
        //     //   link: "/inventory/laporan/delivery-order",
        //     //   hak: ["SUPA", "INV", "INV_RPT_DO"],
        //     // },
      ],
    },
  ],

  ROUTES: [
    // MASTER
    {
      exact: true,
      path: "/inventory/master/buaso",
      page: Buaso,
      hak: ["SUPA", "INV", "INV_MAS_BSO"],
    },
    {
      exact: true,
      path: "/inventory/master/satuan",
      page: Satuan,
      hak: ["SUPA", "INV", "INV_MAS_STN"],
    },
    {
      exact: true,
      path: "/inventory/master/kelompok",
      page: Kelompok,
      hak: ["SUPA", "INV", "INV_MAS_KLB"],
    },
    {
      exact: true,
      path: "/inventory/master/jenis-bahan",
      page: Jenis,
      hak: ["SUPA", "INV", "INV_MAS_JNB"],
    },
    {
      exact: true,
      path: "/inventory/master/kelompok-aset",
      page: KelompokAset,
      hak: ["SUPA", "INV", "INV_MAS_KEL"],
    },
    {
      exact: true,
      path: "/inventory/master/jenis-aset",
      page: JeniAset,
      hak: ["SUPA", "INV", "INV_MAS_JEN"],
    },
    {
      exact: true,
      path: "/inventory/master/pabrikan",
      page: Pabrikan,
      hak: ["SUPA", "INV", "INV_MAS_PAB"],
    },
    {
      exact: true,
      path: "/inventory/master/gudang",
      page: Gudang,
      hak: ["SUPA", "INV", "INV_MAS_GUD"],
    },
    {
      exact: true,
      path: "/inventory/master/depo",
      page: Depo,
      hak: ["SUPA", "INV", "INV_MAS_DPO"],
    },

    // Registrasi Barang Jadi
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi",
    //   page: RegistrasiBarangJadi,
    //   hak: ["SUPA", "PRD_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/tambah",
    //   page: TambahBarangJadi,
    //   hak: ["SUPA", "PRD_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/ubah/:id_item_atribut",
    //   page: UbahBarangJadi,
    //   hak: ["SUPA", "PRD_MAS_BRJ"],
    // },
    // {
    //   exact: true,
    //   path: "/master/registrasi-barang-jadi/detail/:id_item_atribut",
    //   page: DetailBarangJadi,
    //   hak: ["SUPA", "PRD_MAS_BRJ"],
    // },

    // TRANSAKSI
    {
      exact: true,
      path: "/inventory/transaksi/mapping-unit-produksi",
      page: MappingUnitProduksi,
      hak: ["SUPA", "INV", "INV_REG_BHN"],
    },
    // Registrasi BUASO
    {
      exact: true,
      path: "/inventory/transaksi/register-item-bahan",
      page: RegistrasiItemBahan,
      hak: ["SUPA", "INV", "INV_MAS_IBH"],
    },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/register-alat-dan-mesin",
    //   page: RegistrasiAlatMesin,
    //   hak: ["SUPA", "INV", "INV_REG_ALM"],
    // },

    // Delivery Order
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order",
      page: DeliveryOrder,
      hak: ["SUPA", "INV", "INV_TRN_DO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order/list-so",
      page: ListSo,
      hak: ["SUPA", "INV", "INV_TRN_DO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order/tambah-delivery-order/:id_sales_order/:id_gudang",
      page: TambahDeliveryOrder,
      hak: ["SUPA", "INV", "INV_TRN_DO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order/edit-delivery-order/:id",
      page: EditDeliveryOrder,
      hak: ["SUPA", "INV", "INV_TRN_DO"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/delivery-order/detail-delivery-order/:id",
      page: DetailDeliveryOrder,
      hak: ["SUPA", "INV", "INV_TRN_DO"],
    },

    // Surat Jalan
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan",
      page: ListSuratJalan,
      hak: ["SUPA", "INV", "INV_TRN_SJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan/delivery-order",
      page: ListDeliveryOrderSuratJalan,
      hak: ["SUPA", "INV", "INV_TRN_SJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan/tambah/:id",
      page: TambahSuratJalan,
      hak: ["SUPA", "INV", "INV_TRN_SJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan/ubah/:id",
      page: UbahSuratJalan,
      hak: ["SUPA", "INV", "INV_TRN_SJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/surat-jalan/detail/:id",
      page: DetailSuratJalan,
      hak: ["SUPA", "INV", "INV_TRN_SJ"],
    },

    // Purchase Request
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request",
      page: PurchaseRequest,
      hak: ["SUPA", "INV", "INV_TRN_PR"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request/tambah",
      page: TambahPurchaseRequest,
      hak: ["SUPA", "INV", "INV_TRN_PR"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/purchase-request/:id",
      page: UbahPurchaseRequest,
      hak: ["SUPA", "INV", "INV_TRN_PR"],
    },

    // Pengembalian Barang
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang",
      page: PengembalianBarang,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang/faktur-dibatalkan/tambah-pengembalian-barang/:id",
      page: TambahPengembalianBarang,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang/edit-pengembalian-barang/:id",
      page: EditPengembalianBarang,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang/detail-pengembalian-barang/:id",
      page: DetailPengembalianBarang,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/pengembalian-barang/faktur-dibatalkan",
      page: ListFakturDiBatalkan,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },

    // Penerimaan Retur
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur",
      page: PenerimaanRetur,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur/list-faktur-penjualan/tambah-penerimaan-retur/:id",
      page: TambahPenerimaanRetur,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur/edit-penerimaan-retur/:id",
      page: EditPenerimaanRetur,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur/detail-penerimaan-retur/:id",
      page: DetailPenerimaanRetur,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-retur/list-faktur-penjualan",
      page: ListReturPenjulan,
      hak: ["SUPA", "INV", "INV_REG_OVH"],
    },

    // Penerimaan Barang
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-barang",
      page: PenerimaanBarang,
      hak: ["SUPA", "INV", "INV_TRN_PNB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-barang/list",
      page: ListPurchaseOrder,
      hak: ["SUPA", "INV", "INV_TRN_PNB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-barang/tambah/:id",
      page: TambahPenerimaanBarang,
      hak: ["SUPA", "INV", "INV_TRN_PNB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-barang/ubah/:id",
      page: UbahPenerimaanBarang,
      hak: ["SUPA", "INV", "INV_TRN_PNB"],
    },

    // Penerimaan Gabah Kering Giling
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-gabah-kering-giling",
      page: PenerimaanGabahKeringGiling,
      hak: ["SUPA", "INV", "INV_TRN_PGKG"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-beras",
      page: PenerimaanBerasList,
      hak: ["SUPA", "INV", "INV_TRN_PBS"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-beras/detail/:id",
      page: PenerimaanBerasDetail,
      hak: ["SUPA", "INV", "INV_TRN_PBS"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-beras/update/:id",
      page: PenerimaanBerasUpdate,
      hak: ["SUPA", "INV", "INV_TRN_PBS"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-beras/create",
      page: PenerimaanBerasCreate,
      hak: ["SUPA", "INV", "INV_TRN_PBS"],
    },

    // Transfer Produksi Bahan
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/transfer-produksi-bahan",
    //   page: PermintaanBahanProduksi,
    //   hak: ["SUPA", "INV", "INV_TRN_PBP"],
    // },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/transfer-produksi-bahan/data",
    //   page: DataPermintaanBahanProduksi,
    //   hak: ["SUPA", "INV", "INV_TRN_PBP"],
    // },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/transfer-produksi-bahan/cetak",
    //   page: CetakPermintaanBahanProduksi,
    //   hak: ["SUPA", "INV", "INV_TRN_PBP"],
    // },
    // {
    //   exact: true,
    //   path: "/inventory/transaksi/transfer-produksi-bahan/cetak/detail/:id",
    //   page: DetailCetakPermintaanProduksi,
    //   hak: ["SUPA", "INV", "INV_TRN_PBP"],
    // },

    // Transfer Bahan Produksi
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-produksi",
      page: TransferBahanProduksiList,
      hak: ["SUPA", "INV", "INV_TRN_TBP"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-produksi/permintaan-produksi",
      page: TransferBahanPermintaanProduksiList,
      hak: ["SUPA", "INV", "INV_TRN_TBP"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-produksi/tambah/:id",
      page: TransferBahanProduksiTambah,
      hak: ["SUPA", "INV", "INV_TRN_TBP"],
    },

    // Realisasi Sisa Produksi
    {
      exact: true,
      path: "/inventory/transaksi/realisasi-sisa-produksi",
      page: RealisasiSisaProduksi,
      hak: ["SUPA", "INV", "INV_TRN_RSP"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/realisasi-sisa-produksi/list-transfer-sisa-produksi",
      page: ListTransferSisaProduksi,
      hak: ["SUPA", "INV", "INV_TRN_RSP"],
    },

    // Penerimaan Surat Jalan
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-surat-jalan",
      page: PenerimaanSuratJalan,
      hak: ["SUPA", "INV", "INV_TRN_PSJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-surat-jalan/list-surat-jalan",
      page: ListSuratJalanPenerimaan,
      hak: ["SUPA", "INV", "INV_TRN_PSJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-surat-jalan/tambah/:id",
      page: TambahPenerimaanSuratJalan,
      hak: ["SUPA", "INV", "INV_TRN_PSJ"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/penerimaan-surat-jalan/detail/:id",
      page: DetailPenerimaanSuratJalan,
      hak: ["SUPA", "INV", "INV_TRN_PSJ"],
    },

    // Mutasi Antar Gudang
    {
      exact: true,
      path: "/inventory/transaksi/mutasi-antar-gudang",
      page: MutasiAntarGudang,
      hak: ["SUPA", "INV", "INV_TRN_MAG"],
    },

    // Transfer Bahan Job Mix
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-job-mix",
      page: TransferBahanJobMix,
      hak: ["SUPA", "INV", "INV_TRN_TJB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-job-mix/list-permintaan-bahan",
      page: ListPermintaanBahanJobMix,
      hak: ["SUPA", "INV", "INV_TRN_TJB"],
    },
    {
      exact: true,
      path: "/inventory/transaksi/transfer-bahan-job-mix/tambah/:id_jobmix_permintaan",
      page: TambahTransferBahanJobMix,
      hak: ["SUPA", "INV", "INV_TRN_TJB"],
    },

    // Setup Stok Awal
    {
      exact: true,
      path: "/inventory/transaksi/setup-stok-awal",
      page: SetupStokAwal,
      hak: ["SUPA", "INV", "INV_TRN_SSA"],
    },

    // LAPORAN
    {
      exact: true,
      path: "/inventory/laporan/kartu-stock-material",
      page: KartuStockMaterial,
      hak: ["SUPA", "INV", "INV_LAP_KST"],
    },
    {
      exact: true,
      path: "/inventory/laporan/purchase-request",
      page: LaporanPurchaseRequest,
      hak: ["SUPA", "INV", "INV_RPT_LPR"],
    },
    {
      exact: true,
      path: "/inventory/laporan/delivery-order",
      page: LaporanDeliveryOrder,
      hak: ["SUPA", "INV", "INV_RPT_DO"],
    },
    {
      exact: true,
      path: "/inventory/laporan/stok-terbaru",
      page: StokTerbaru,
      hak: ["SUPA", "INV", "INV_LAP_SUP"],
    }
  ],
}
