// React
import React, { Component } from "react";

// Component
import {
  Table
} from "../../../../components";

// API
import { PurchaseRequestApi } from "../../../../api";
import Axios from "axios";

export class ShowData extends Component {
  render() {
    return (
      <div className="d-flex flex-row">
        <div className="text-secondary mb-2 mr-3" style={{ width: 170 }}>
          {this.props.title}
        </div>
        <span>:</span>
        <div
          className="text-dark"
          style={{
            fontWeight: 200,
            paddingLeft: 40,
          }}
        >
          {this.props.text ? this.props.text : "-"}
        </div>
      </div>
    );
  }
}

export class CetakPurchaseRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        detail: []
      },
    };
  }

  componentDidMount() {
    Axios.all([
      PurchaseRequestApi.single(this.props.idRef)
    ])
      .then(
        Axios.spread((res, bahan, upah, vendor) => {
          this.setState({
            data: res.data.data
          })
        })
      )
  }

  componentWillUnmount() {
    this.setState({
      data: {}
    });
  }

  render() {
    const data = this.state.data;

    return (
      <div style={{ marginTop: 100 }} className="mx-2">
        <div className="row">
          <div className="col-6">
            <div className="mb-2">
              <ShowData
                title="Tgl. Purchase Request"
                text={data.tgl_purchase_request}
              />

              <ShowData
                title="No Purchase Request"
                text={data.no_purchase_request}
              />
            </div>
          </div>

          <div className="col-6">
            <div className="mb-2">
              <ShowData
                title="Tgl. Pemakaian"
                text={data.tgl_pemakaian}
              />

              <ShowData
                title="Keperluan"
                text={data.keperluan}
              />
            </div>
          </div>
        </div>

        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>Kode Item</th>
              <th>Nama Item</th>
              <th>Satuan</th>
              <th>Qty</th>
            </tr>
          </thead>

          <tbody>
            {data.detail.length > 0 ? (
              data.detail.map((val, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{val.kode_item}</td>
                  <td>{val.nama_item}</td>
                  <td>{val.nama_satuan}</td>
                  <td className="text-right">{val.qty}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="my-2 font-weight-bold text-center">Tidak Ada Data</td>
              </tr>
            )}
          </tbody>
        </Table>

        <div>
          <strong>Catatan : </strong>
          <br></br>
          {data.catatan_purchase_request}
        </div>
      </div>
    );
  }
}

export default CetakPurchaseRequest;
