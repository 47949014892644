import Services from "../../../services"

class PenerimaanBerasApi {
  getPage(params) {
    return Services.get("/penerimaan_beras/page", { params })
  }
  getSingle(params) {
    return Services.get("/penerimaan_beras/single", { params })
  }
  getNomor(params) {
    return Services.get("/penerimaan_beras/no_baru", { params })
  }
  create(value) {
    return Services.post("penerimaan_beras", value)
  }
  update(value) {
    return Services.put("/penerimaan_beras", value);
  }
  dropdownItem() {
    return Services.get("/dropdown/bahan")
  }
  dropdownGudang() {
    return Services.get("/gudang/dropdown")
  }
  export(params) {
    return Services.get("/penerimaan_beras/export", { params })
  }
}
export default new PenerimaanBerasApi()
