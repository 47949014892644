const mappingDataItem = (data, label) => {
    return data.data.data.map(val => {
        return {
            value: val[`id_${label}`],
            label: `${val.kode_item} | ${val.nama_item}`,
            ...val
        }
    })
}

export default mappingDataItem